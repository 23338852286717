import { NORMAL_ACCOUNT, BP_ACCOUNT, PROXY_ACCOUNT } from "components/global"
import { CheckTypeOfUser, fetchTable, GetTokenInfo, handleError } from "services/ApiService"
import { sessionKit } from "services/SessionKit"

const initState = {
  accountName: "",
  permission: "",
  userType: NORMAL_ACCOUNT,
  accountOwner: "",
  balance: [],
  eosBalance: "0 EOS",
}

/**
 * @type {import("@rematch/core").Model}
 */
export const wallet = {
  state: initState,
  reducers: {
    reset() {
      return initState
    },
    setAccountName(state, payload) {
      return {
        ...state,
        ...payload,
      }
    },
    setUserType(state, userType) {
      return {
        ...state,
        userType,
      }
    },
    setAccountOwner(state, accountOwner) {
      return {
        ...state,
        accountOwner,
      }
    },
    setBalance(state, balance) {
      return {
        ...state,
        balance,
        eosBalance: balance[0] || "0 EOS",
      }
    },
  },
  effects: (dispatch) => ({
    async fetchBalance(account) {
      const b = await GetTokenInfo(account)
      this.setBalance(b)
    },
    async fetchAccountType(account) {
      let accountType = NORMAL_ACCOUNT
      const accountQueryRs = await fetchTable("eosio", "eosio", "voters", account, account, 100)

      if (accountQueryRs && accountQueryRs.length && accountQueryRs[0]) {
        if (accountQueryRs[0].is_proxy) {
          //proxy account
          accountType = PROXY_ACCOUNT
        } else {
          //Voter acoount
          const accountOwner = accountQueryRs[0].owner
          this.setAccountOwner(accountOwner)
          const IsNormalAccount = await fetchTable("eosio", "eosio", "producers", accountOwner, accountOwner, 100)

          //Bp Account
          if (IsNormalAccount && IsNormalAccount.length) {
            accountType = BP_ACCOUNT
          }
        }
      }
      this.setUserType(accountType)
    },

    async restoreLogin() {
      const session = await sessionKit.restore()
      if (session) {
        this.login(session)
      }
    },

    /**
     * @param {*} session This param is only used in restoring login API above
     */
    async login(session = undefined) {
      try {
        //login to wallet
        if (!session) {
          const loginRs = await sessionKit.login()
          session = loginRs.session
        }

        if (session) {
          const accountName = String(session.permissionLevel.actor)
          // // FOR TESTING
          // const accountName = process.env.REACT_APP_GENEREOS_PROXY; //Proxies Y
          // const accountName = "aus1genereos"; //BP Y

          this.setAccountName({
            accountName,
            permission: String(session.permissionLevel.permission),
          })
          await this.fetchAccountType(accountName)
          await CheckTypeOfUser(accountName)
        }
      } catch (error) {
        console.log(error)
        handleError(error, dispatch)
      }
    },

    async logout() {
      try {
        this.setAccountName({
          accountName: "",
          permission: "",
        })

        //fetch all wallet list
        await sessionKit.logout()
      } catch (error) {
        console.log(error)
        handleError(error, dispatch)
      }
    },
  }),
}
