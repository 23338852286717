// React core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { multilanguage, loadLanguages } from 'services/multilanguage';
import { BP_ACCOUNT, PROXY_ACCOUNT } from 'components/global';

import Genpool from 'assets/images/Genpool-logo.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDashBoard: false,
        }
    }

    componentDidMount = () => {
        this.loadLanguages()
        this.IsDisplayDashBoard()
    }

    version = () => {
        console.log('Genpool version v1.0.6 3 6 2020')
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.wallet !== prevProps.wallet){
            this.IsDisplayDashBoard()
        }
    }

    IsDisplayDashBoard = () => {
        if(this.props.wallet?.accountName){
            const userType = this.props.wallet.userType
            if(userType === PROXY_ACCOUNT || userType === BP_ACCOUNT){
                this.setState({
                    isDashBoard: true,
                })
            }else{
                this.setState({
                    isDashBoard: false,
                })
            }
        }else{
            this.setState({
                isDashBoard: false,
            })
        }
    }

    render() {
        const { strings } = this.props
        
        return (
            <div className="footer-2">
                <div className="footer-columns w-row">
                    <div className="w-col w-col-3">
                        <div className="w-clearfix">
                            <Link to="/" className="brand-logo footer-logo w-nav-brand">
                                <img src={Genpool} width={100} alt="" className="image-10" />
                            </Link>
                        </div>
                        <div className="footer-social-icons">
                            <a href="https://t.me/generEOS" className="footer-social fb w-inline-block" rel="noopener noreferrer" target="_blank" > </a>
                            <a href="https://x.com/GenpoolStake" className="footer-social twitter w-inline-block" rel="noopener noreferrer" target="_blank" > </a>
                            {/* <a href="https://medium.com/@generEOS" className="footer-social medium w-inline-block" rel="noopener noreferrer" target="_blank" > </a> */}
                        </div>
                    </div>
                    <div className="w-col w-col-9">
                        <div className="footer-links w-row">
                            <div className="w-col w-col-3">
                                <h6 className="footer-nav-title">{strings["Services"]}</h6>
                                {
                                    this.state.isDashBoard ? 
                                        <Link to="/dashboard" className="footer-link text-small">{strings["Dashboard"]}</Link> : null
                                }
                                <Link to="/register-proxy" className="footer-link text-small">{strings["ListProxy"]}</Link></div>
                            <div className="w-col w-col-3">
                                <h6 className="footer-nav-title">{strings["Resources"]}</h6>
                                <a href="https://genpool.zendesk.com/hc/en-us" className="footer-link text-small">{strings["FAQ"]}</a>
                            </div>
                            <div className="w-col w-col-3">
                                <h6 className="footer-nav-title">{strings["Company"]}</h6><a href="http://genereos.io/" className="footer-link text-small">{strings["About"]}</a></div>
                            <div className="w-col w-col-3">
                                <h6 className="footer-nav-title">{strings["Contact"]}</h6><Link to="mailto:contact@genereos.io?subject=Genpool%20Inquiry%20" className="footer-link text-small">contact@genereos.io</Link></div>
                        </div>
                    </div>
                </div>
                <div className="footer-copy">
                    <div className="w-row">
                        <div className="w-col w-col-5">
                            <div className="footer-copy-text hint" onClick={this.version}>{strings["Copyright"]}</div>
                        </div>
                        <div className="w-clearfix w-col w-col-7">
                            <Link to="/docs/terms" className="footer-copy-link hint">{strings["Terms"]}</Link>
                            <Link to="/docs/policy" className="footer-copy-link hint">{strings["Privacy"]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps
)(multilanguage(Footer))
