const { default: BigNumber } = require("bignumber.js")

function fmtBalance(balance, decimalPlace = 4) {
  return BigNumber(balance).dp(decimalPlace).toNumber()
}

function fmtSymbol(amt, symbol = "EOS", decimalPlace = 4) {
  return `${parseFloat(amt).toFixed(decimalPlace)} ${symbol}`
}

function parseAmtSymbol(balance) {
  const [amt, symbol] = balance.split(" ")
  return [parseFloat(amt), symbol]
}

function randomId(length = 8) {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return `id_${result}`
}

/**
 *
 * @param {number} eos
 * @param {number} totalLendable
 * @param {number} totalRex
 * @returns {number}
 */
function convertEosToRex(eos, totalLendable, totalRex) {
  const S0 = parseFloat(totalLendable)
  const R0 = parseFloat(totalRex)
  const S1 = S0 + +eos
  const R1 = (S1 * R0) / S0
  return parseFloat(parseFloat((R1 - R0).toString()).toFixed(4))
}

/**
 *
 * @param {number} rex
 * @param {number} totalLendable
 * @param {number} totalRex
 * @returns {number}
 */
function convertRexToEos(rex, totalLendable, totalRex) {
  const S0 = parseFloat(totalLendable)
  const R0 = parseFloat(totalRex)
  const R1 = R0 + +rex
  const S1 = (S0 * R1) / R0
  return parseFloat(parseFloat((S1 - S0).toString()).toFixed(4))
}

/**
 *
 * @param {number|string} num
 * @returns {string}
 */
const commaNumber = (num) => {
  if (isNaN(num) || num === null || num === undefined) return 0
  return parseFloat(num).toLocaleString()
}

/**
 * 
 * @param {number} num 
 * @returns {string}
 */
const readableNumber = (num) => {
  // convert to k,m, b, t
  if(num < 1000) return num.toString();
  if(num < 1000000) return (num/1000).toFixed(2) + "K";
  if(num < 1000000000) return (num/1000000).toFixed(2) + "M";
  if(num < 1000000000000) return (num/1000000000).toFixed(2) + "B";
  return (num/1000000000000).toFixed(2) + "T";
}

module.exports = {
  fmtBalance,
  fmtSymbol,
  randomId,
  parseAmtSymbol,
  convertEosToRex,
  convertRexToEos,
  commaNumber,
  readableNumber,
}
