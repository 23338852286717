// React core
import React, { Component } from 'react';
import { multilanguage, loadLanguages } from 'services/multilanguage'

class ErrorPage extends Component {
    componentDidMount = () => {
        this.loadLanguages();
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    render() {
        const { strings } = this.props
        return (
            <div className="utility-page-wrap">
                <div className="utility-page-content w-form">
                    <img src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg" alt="" />
                    <h2>{strings["headerError"]}</h2>
                    <div>{strings["detailError"]}</div>
                </div>
            </div>
        );
    }
}

// Export a redux connected component
export default multilanguage(ErrorPage)
