// React core
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer } from 'components'

import { multilanguage, loadLanguages } from 'services/multilanguage'

import iziToast from 'izitoast';
import { TOAST, ACCOUNT_CONTRACT, BP_TABLE, PROXYS_TABLE} from 'components/global'

import { fetchTable, pushAction, imageExists, GetTokenInfo } from 'services/ApiService'

import default_logo from 'assets/images/default.gif'
import sending from 'assets/images/sending.svg'
import sending_dark from 'assets/images/sending_dark.svg'

const TAB1 = 1
const TAB2 = 2
const TAB3 = 3
const TAB4 = 4

const TIMEOUT_READ_TABLE = 2000

class BpInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabId: TAB3,            //select tab
            BpName: '',             //bp name
            RegisterProxieTable: [],      //bp table
            ListProxy: [],      //proxy list

            depositAmount: 0,   //deposit amount

            eosValue: 0,        //eos value to add in pair
            ProxySelect: '',

            fundBpValue: '', //liquid eos
            IsShowBpFundInGenpool: false,
            fundBpInGenPoolValue: '', //Genpool funds

            APRAmount: '',

            NumberOfSelect: 0,
            NumberOfWhiteList: 0,

            isSendingActionList: {
                depositBP: false,
                claimRefund: false,
                addProxy: false,
            },
            isSendingRemoveList: []
        }
    }

    componentDidMount = async () => {
        this.loadLanguages()
        this.loadFundOfBP()
        this.updateProxyTable()

        if (this.props.match && this.props.match.params) {
            // console.log('tam test nest link pross', this.props.match)
            const BpName = this.props.match.params.BpId
            let tmpTable = []

            const BpTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, "", "", 100);

            if (BpTable && BpTable.length) {
                for(let i = 0; i < BpTable.length; ++i)
                if(BpName === BpTable[i].account){
                    tmpTable = (BpTable[i].proxy_donate)
                    this.setState({
                        fundBpInGenPoolValue: parseFloat(BpTable[i].fund).toFixed(4).toLocaleString('en') + ' EOS'
                    })
                }
            }
            
            let tempSendingRemoveList = []
            for(let i = 0; i < tmpTable.length; ++i){
                tempSendingRemoveList.push(false)
            }

            this.setState({
                BpName: BpName,
                RegisterProxieTable: tmpTable,
                isSendingRemoveList: tempSendingRemoveList
            })
        }
    }

    componentWillUnmount = () => {
        this.setState({
            fundBpValue: '',
            fundBpInGenPoolValue: '',
            IsShowBpFundInGenpool: false,
        })
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    loadFundOfBP = async () => {
        if(this.props.wallet?.accountName){
            const account = this.props.wallet.accountName
            // const account = 'aus1genereos'
            const IsBp = await this.IsBpAccount(account)

            if(IsBp){
                const Funds = await GetTokenInfo(account)
                if(Funds){
                    this.setState({
                        fundBpValue: parseFloat(Funds).toFixed(4).toLocaleString('en') + ' EOS',
                        IsShowBpFundInGenpool: true,
                    })
                    
                }
            }
        }
    }

    updateStatusActionList = (action, isSending) => {
        let actionList = this.state.isSendingActionList
        
        actionList[`${action}`] = isSending
        this.setState({
            isSendingActionList: actionList
        })
    }

    updateStatusRemoveList = (index, isSending) => {
        let actionList = this.state.isSendingRemoveList
    
        for(let i = 0; i < actionList.length; ++i){
          if(i === index){
            actionList[i] = isSending
          }
        }
        this.setState({
            isSendingRemoveList: actionList
        })
      }

    IsBpAccount = async (account) => {
        let IsBp = false

        const AccountType = await fetchTable('eosio', 'eosio', 'voters', account, account, 100);

        if (AccountType && AccountType.length && AccountType[0] && AccountType[0] && AccountType[0].is_proxy === 0) {
            //Voter acoount
            const tmpAccount = AccountType[0].owner
            const IsNormalAccount = await fetchTable('eosio', 'eosio', 'producers', tmpAccount, tmpAccount, 100);
            // console.log('tam IsNormalAccount', IsNormalAccount)

            //Bp Account
            if (IsNormalAccount && IsNormalAccount.length) {
                IsBp = true
            }
        }

        return IsBp
    }

    updateRegProxytable = async () => {
        if (this.state.BpName) {
            const BpName = this.state.BpName
            let tmpTable = []

            const BpTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, "", "", 100);

            if (BpTable && BpTable.length) {
                for(let i = 0; i < BpTable.length; ++i)
                if(BpName === BpTable[i].account){
                    tmpTable = (BpTable[i].proxy_donate)
                }
            }
            // console.log('tam BpTable', tmpTable)

            let tempSendingRemoveList = []
            for(let i = 0; i < tmpTable.length; ++i){
                tempSendingRemoveList.push(false)
            }

            this.setState({
                RegisterProxieTable: tmpTable,
                isSendingRemoveList: tempSendingRemoveList,
            })
        }
    }

    updateProxyTable = async () => {
        const proxyTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, PROXYS_TABLE, "", "", 50)
        // console.log('tam proxyTable', proxyTable)
        let tmpProxy = []
        if(proxyTable && proxyTable.length){
            for(let i = 0; i < proxyTable.length; ++i){
                tmpProxy.push(proxyTable[i].account)
            }
        }

        this.setState({
            ListProxy: tmpProxy
        })
    }

    selectTab = (id) => () => {
        this.setState({
            tabId : id
        })
    }

    updateDepositAmount = (depositAmount) => {
        this.setState({
            depositAmount: depositAmount.target.value ? depositAmount.target.value : 0,
        })
    }

    updateEOSValue = (eosValue) => {
        this.setState({
            eosValue: eosValue.target.value ? eosValue.target.value : 0,
        })
    }

    updateAPRAmount = (APRAmount) => {
        this.setState({
            APRAmount: APRAmount.target.value ? APRAmount.target.value : 0,
        })
    }

    handleDepositBp = async () => {
        if(this.state.depositAmount === 0){
            iziToast.show({
                message: this.props.strings["InvalidAmount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.props.wallet?.accountName){
            this.updateStatusActionList('depositBP', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission
      
            const account = 'eosio.token'
            const action = 'transfer'

            const quantity = parseFloat(this.state.depositAmount).toFixed(4) + ' EOS'

            const data = {
                from: actor,
                to: ACCOUNT_CONTRACT,
                quantity: quantity,
                memo: this.state.BpName,
            }
           
            const deposit = await pushAction(account, action, actor, data, permission)
            // console.log('tam deposit', deposit)
            if(deposit){
                iziToast.show({
                    message: this.props.strings["DepositOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusActionList('depositBP', false)

        }else{
            this.props.login()
        }
    }

    handleClaim = async () => {
        if(this.props.wallet?.accountName){
            this.updateStatusActionList('claimRefund', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission
      
            const account = ACCOUNT_CONTRACT
            const action = 'claimfund'

            const producer = this.state.BpName

            const data = {
                producer: producer,
            }
           
            const claimRequest = await pushAction(account, action, actor, data, permission)
            // console.log('tam deposit', claimRequest)
            if(claimRequest){
                iziToast.show({
                    message: this.props.strings["ClaimOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusActionList('claimRefund', false)

        }else{
            this.props.login()
        }
    }

    RegProxyTable = () => {
        // if (this.state.RegisterProxieTable && this.state.RegisterProxieTable.length) {
        //     let rows = []
        //     for (let i = 0; i < this.state.RegisterProxieTable.length; ++i) {
        //         const rowData = this.state.RegisterProxieTable[i]
        //         rows.push(
        //             <div key={i} className="rowinternal1">
        //                 <div className="row2internal1">
        //                     <div className="internalbody">{rowData.key}</div>
        //                 </div>
        //                 <div className="row2internal2-copy">
        //                     <div className="body1 c">{rowData.value}</div>
        //                 </div>
        //                 <div className="row2internal2-copy"><div className="link-6" onClick={this.handleRemove(rowData)}>{this.props.strings["remove"]}</div></div>
        //                 {/* <div className="_8">
        //                     <div className="body1 c votea">More&nbsp;&gt;</div>
        //                 </div> */}
        //             </div>
        //         )
        //     }
        //     return rows
        // }
        if (this.state.RegisterProxieTable && this.state.RegisterProxieTable.length) {
            let rows = []
            for (let i = 0; i < this.state.RegisterProxieTable.length; ++i) {
                const rowData = this.state.RegisterProxieTable[i]

                rows.push(
                    <tr key={imageExists} className="bp-table-body">
                        <td className="bp-column-1">
                            <div className="bp-column-text-1"> {rowData.key} </div>
                        </td>
                        <td className="bp-column-2">
                            <div className="bp-column-text-2"> {rowData.value} </div>
                        </td>
                        <td className="bp-column-3">
                            <div className="bp-column-text-3" onClick={this.handleRemove(i, rowData)}>
                                {
                                    this.state.isSendingRemoveList[i] ?
                                        <img src={sending_dark} alt="" className="image-bpInternal-3" />
                                        : <div>{this.props.strings["remove"]}</div>
                                }
                            </div>
                        </td>
                    </tr>
                )
            }

            return rows
        }
    }

    handleRemove = (index, pair) => async () => {
        if (this.props.wallet?.accountName) {
            this.updateStatusRemoveList(index, true)

            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            const account = ACCOUNT_CONTRACT
            const action = 'configbp'

            //get list
            let proxy_donate = this.state.RegisterProxieTable.slice()
            
            //remove key from list
            proxy_donate = proxy_donate.filter(function(value){
                return value.key !== pair.key;
            });

            proxy_donate = proxy_donate.sort((a, b) => (a.key > b.key ? 1 : -1))

            const producer = this.state.BpName

            const data = {
                producer: producer,
                proxy_donate: proxy_donate,
            }

            const remoteProducer = await pushAction(account, action, actor, data, permission)
            if(remoteProducer){
                setTimeout(() => {
                    this.updateRegProxytable();
                }, TIMEOUT_READ_TABLE);

                iziToast.show({
                    message: this.props.strings["RemoveOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusRemoveList(index, false)

            // console.log('tam reg', remoteProducer)
        } else {
            this.props.login()

        }

    }

    handleAdd = async () => {
        if(!this.state.ProxySelect || this.state.ProxySelect === '' 
            || this.state.ProxySelect === 'Select proxy' || this.state.ProxySelect === '选择代理' 
                || this.state.ProxySelect === '프록시 선택하기' || this.state.ProxySelect === this.props.strings["SelectProxy"]){
            iziToast.show({
                message: this.props.strings["InvalidProxy"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if(this.props.wallet?.accountName) {
            this.updateStatusActionList('addProxy', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            const account = ACCOUNT_CONTRACT
            const action = 'configbp'

            //get list
            let proxy_donate = this.state.RegisterProxieTable.slice()

            //check proxy is exited in list or not
            const IsFind = proxy_donate.find(element => element.key === this.state.ProxySelect)
            if(IsFind){
                iziToast.show({
                    message: this.props.strings["Inlist"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }else{
                //add key to list
                const newPair = {
                    key: this.state.ProxySelect,
                    value: parseFloat(this.state.eosValue ? this.state.eosValue : 0).toFixed(4) + ' EOS'
                }
                proxy_donate.push(newPair)
                
                proxy_donate = proxy_donate.sort((a, b) => (a.key > b.key ? 1 : -1))
    
                const producer = this.state.BpName
    
                const data = {
                    producer: producer,
                    proxy_donate: proxy_donate,
                }
    
                const addProducer = await pushAction(account, action, actor, data, permission)
                if(addProducer){
                    setTimeout(() => {
                        this.updateRegProxytable();
                    }, TIMEOUT_READ_TABLE);

                    iziToast.show({
                        message: this.props.strings["AddOk"],
                        progressBar: false,
                        timeout: TOAST.TimeOut,
                        backgroundColor: TOAST.SucessColor,
                        messageSize: TOAST.MessageSize,
                        position: TOAST.Position,
                        messageColor: TOAST.MessageColor,
                    });
                }
    
                // console.log('tam reg', addProducer)
            }
            this.updateStatusActionList('addProxy', false)

        } else {
            this.props.login()

        }

    }

    handleSelect = (ProxySelect) => {
        this.setState({
            ProxySelect: ProxySelect.target.value ? ProxySelect.target.value : '',
        })
    }

    handleAPRAmount = () => {
        if(this.state.APRAmount === ''){
            iziToast.show({
                message: this.props.strings["InvalidAmount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else{

        }
    }

    render() {
        var ListProxy = this.state.ListProxy ? this.state.ListProxy.map((proxy, index) => {
            return(
                <option key={index} value={proxy} >{proxy}</option>
            )
        }) : null

        const default_url = default_logo
        const imageUrl = `https://raw.githubusercontent.com/sun1211/eos-bp-logo/master/${this.state.BpName}/${this.state.BpName}.png`

        const { strings } = this.props

        return (
            <div className="body">
                <div className="section-6"><img src={`${imageExists(imageUrl) ? imageUrl : default_url}`} width={120} alt="" className="image-11" />
                    <h1 className="heading-10">{this.state.BpName}</h1>
                    <div data-duration-in={300} data-duration-out={100} className="tabs-3 w-tabs">
                        <div className="tabs-menu-4 w-tab-menu">
                            <div data-w-tab="Tab 1" onClick={this.selectTab(TAB1)} 
                                        className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB1 ? 'w--current' : ''}`}>
                                <div className="text-block-18">{strings["FundAccount"]}</div>
                            </div>
                            <div data-w-tab="Tab 2" onClick={this.selectTab(TAB2)} 
                                        className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB2 ? 'w--current' : ''}`}>
                                <div className="text-block-29">{strings["Refund"]}</div>
                            </div>
                            <div data-w-tab="Tab 3" onClick={this.selectTab(TAB3)} 
                                        className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB3 ? 'w--current' : ''}`}>
                                <div className="text-block-30">{strings['RegisteredProxies']}</div>
                            </div>
                            <div data-w-tab="Tab 4" onClick={this.selectTab(TAB4)} 
                                        className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB4 ? 'w--current' : ''}`}>
                                <div className="text-block-18">{strings['Notifications']}</div>
                            </div>
                        </div>
                        <div className="tabs-content-4 w-tab-content">
                            <div data-w-tab="Tab 1" className={`tab-pane-tab-1 w-tab-pane ${this.state.tabId === TAB1 ? 'w--tab-active':''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["FundBP"]}</h2>
                                    {
                                        this.state.fundBpValue !== '' ? <h2 className="text-block-13 info left bold">{strings["FundBPEOS"]} {this.state.fundBpValue}</h2> : null
                                    }
                                    {
                                        (this.state.fundBpInGenPoolValue !== '' && this.state.IsShowBpFundInGenpool )? <h2 className="text-block-13 info left bold">{strings["FundBPGenpool"]} {this.state.fundBpInGenPoolValue}</h2> : null
                                    }
                                    
                                    <div className="form-block-2 w-form">
                                        <form id="email-form2" name="email-form" data-name="Email Form">
                                        <input type="number" className="text-field big left w-input" maxLength={256} placeholder={this.props.strings["eosAmount"]} id="name-2" 
                                            value={this.state.depositAmount || ''} onChange={this.updateDepositAmount}/>
                                        </form>
                                        <div className="w-form-done">
                                            <div>{strings["FormDone"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["Oops"]}</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info left">
                                        {strings["Info1"]}
                                     </div>
                                     <div className="button4 w-button-edit bpInterBtn-1" onClick={this.handleDepositBp}>
                                     {
                                         this.state.isSendingActionList.depositBP ? 
                                            <img src={sending} alt="" className="image-bpInternal-1"/>
                                            : <div> {strings["Deposit"]} </div>
                                     }
                                     </div>
                                </div>
                            </div>
                            <div data-w-tab="Tab 2" className={`w-tab-pane ${this.state.tabId === TAB2 ? 'w--tab-active':''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["ClaimFunds"]}</h2>
                                    <div className="form-block-2 w-form">
                                        <form id="email-form1" name="email-form" data-name="Email Form">
                                        {/* <input type="text" className="text-field big left w-input" maxLength={256} name="name-2" data-name="Name 2" placeholder={1234} id="name-2" /> */}
                                        </form>
                                        <div className="w-form-done">
                                            <div>{strings["FormDone"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["Oops"]}</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info left">
                                        {strings["Info2"]}‍
                                    </div>
                                    <div href="#" className="button4 w-button-edit bpInterBtn-2" onClick={this.handleClaim}>
                                    {
                                        this.state.isSendingActionList.claimRefund ? 
                                            <img src={sending} alt="" className="image-bpInternal-2"/>
                                                :<div>{strings["Claim"]}</div>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="Tab 3" className={`w-tab-pane ${this.state.tabId === TAB3 ? 'w--tab-active':''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["RegisteredProxies"]}:</h2>
                                    <div className="text-block-13 info left">
                                        {strings["Info3"]}
                                    ‍</div>
                                    <div className="proxyinternal">
                                        <div className="bp-table-continer-home">
                                            <div className="bp-table-home">
                                                <div className="bp-table">
                                                    <table>
                                                        <thead>
                                                            <tr className="bp-table-head">
                                                                <th className="bp-column-1">
                                                                    <div className="bp-column-text-1">{strings["Proxy"]}</div>
                                                                </th>
                                                                <th className="bp-column-2">
                                                                    <div className="bp-column-text-2">{strings["DonationAmount"]}</div>
                                                                </th>
                                                                <th className="bp-column-3">
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.RegProxyTable()}

                                                            <tr key={-1} className="bp-table-body">
                                                                <td className="bp-column-1">
                                                                    <div className="bp-column-text-1">
                                                                        <div className="w-form">
                                                                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form-2">
                                                                                <select id="field-2" className="select-field-2 w-select" value={this.state.ProxySelect} onChange={this.handleSelect}>
                                                                                    <option value={this.props.strings["SelectProxy"]}>{this.props.strings["SelectProxy"]}</option>
                                                                                    {ListProxy}
                                                                                </select>
                                                                            </form>
                                                                            <div className="w-form-done">
                                                                                <div>{strings["FormDone"]}</div>
                                                                            </div>
                                                                            <div className="w-form-fail">
                                                                                <div>{strings["Oops"]}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="bp-column-2">
                                                                    <div className="bp-column-text-2 daily-amount">
                                                                        <div className="w-form">
                                                                            <input type="number" className="text-field w-input" maxLength={256} placeholder={this.props.strings["DayAmount"]} id="name-2"
                                                                                value={this.state.eosValue || ''} onChange={this.updateEOSValue} />
                                                                            <div className="w-form-done">
                                                                                <div>{strings["FormDone"]}</div>
                                                                            </div>
                                                                            <div className="w-form-fail">
                                                                                <div>{strings["Oops"]}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="bp-column-3">
                                                                    <div className="bp-column-text-3" onClick={this.handleAdd} >
                                                                    {
                                                                        this.state.isSendingActionList.addProxy ? 
                                                                            <img src={sending_dark} alt="" className="image-bpInternal-3"/>
                                                                                :<div>{strings["Add"]}</div>
                                                                    }
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="Tab 4" className={`tab-pane-tab-1 w-tab-pane ${this.state.tabId === TAB4 ? 'w--tab-active':''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["Notifications"]} </h2>
                                    <div className="text-block-13 info left">{strings["JoinOur"]} <a rel="noopener noreferrer" target="_blank" href='https://t.me/colinproxybot' className="link-12">{strings["Telegram"]}</a> {strings["lowBP"]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
      wallet: state.wallet,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      ...dispatch.wallet,
    };
  };
  
  // Export a redux connected component
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(multilanguage(BpInternal))
