// React core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router , Route, Switch, Redirect } from "react-router-dom";

import "izitoast/dist/css/iziToast.css";

import {
  ErrorPage,
  MainPage,
  DashBoard,
  ProxyInternal,
  RegisterProxy,
  BpInternal,
  Docs,
  Navigation
} from 'components';
import history from 'services/History'
// import BpInternal from './BpInternal';
import BpInternalAction from './BpInternalAction';
import BpPortal from './BpPortal';

class App extends Component {
  componentDidMount() {
    // console.log("==> Resotoring login")
    this.props.restoreLogin()
  }

  render() {
    return (
      <Router history={history} >
        <div className="root">
        <Navigation />
          <Switch>
            <Redirect exact strict from={"/"} to={"/genpool-proxy"} />
            <Redirect exact strict from={"/genpool-proxy"} to={"/genpool-proxy/stake"} />
            <Route path="/genpool-proxy" component={MainPage} />
            <Route path="/public-proxy" component={MainPage} exact />
            <Route path="/exchange-proxy" component={MainPage} exact />
            <Route path="/dashboard" component={DashBoard} exact />
            <Route path="/dashboard/proxy/" component={DashBoard} exact />
            <Route path="/dashboard/bp/" component={DashBoard} exact />
            <Route path="/dashboard/proxy/:proxyId" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/fund-account" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/refund" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/set-apr" component={ProxyInternal} exact />
            <Route path="/dashboard/bp/:BpId" component={BpInternal} exact />
            <Route path="/register-proxy" component={RegisterProxy} exact />
            <Route path="/bp-internal-action" component={BpInternalAction} exact />
            <Route path="/bp-portal" component={BpPortal} exact />
            <Route path="/docs" component={Docs} exact />
            <Route path="/docs/policy" component={Docs} exact />
            <Route path="/docs/terms" component={Docs} exact />
            <Route component={ErrorPage} />
          </Switch>

        </div>
      </Router >
    );
  }
}

const mapState = (_state) => ({});

const mapDispatch = (dispatch) => ({
  ...dispatch.wallet,
});

// Export a redux connected component
export default connect(mapState, mapDispatch)(App);
