import { SUCCESS } from "components/global"
import { GetHistoryApr, GetProxiesTable } from "services/ApiService"

const MAX_DAY_PER_MONTH = 32

/**
 * @type {import("@rematch/core").Model}
 */
export const proxyTable = {
  state: {
    publicProxies: [],
    exchangeProxies: [],
    moreInformation: [],
    moreInformationEx: [],
    listHistApr: [],
    historyApr: {},
    summaryTotal: 0,
    genpoolProxy: {},
  },
  reducers: {
    setPublicProxies(state, payload) {
      const publicProxies = payload.sort((a, b) => (parseFloat(a.stakeEOS) < parseFloat(b.stakeEOS) ? 1 : -1))
      const genpoolProxy = publicProxies.find((it) => it.name == "genpoolproxy")
      const moreInformation = publicProxies.map(() => ({
        isShow: false,
        EOSAmount: 0,
        EOSDaily: 0,
        EOSAnnually: 0,
      }))

      return {
        ...state,
        publicProxies,
        genpoolProxy,
        moreInformation,
      }
    },
    showMoreInformation(state, idx) {
      const v = state.moreInformation[idx]
      v.isShow = !v.isShow
      return {
        ...state,
        moreInformation: [...state.moreInformation],
      }
    },
    setExchangeProxies(state, payload) {
      const exchangeProxies = payload.sort((a, b) => (parseFloat(a.stakeEOS) < parseFloat(b.stakeEOS) ? 1 : -1))
      const moreInformationEx = exchangeProxies.map(() => ({
        isShow: false,
        EOSAmount: 0,
        EOSDaily: 0,
        EOSAnnually: 0,
      }))

      return {
        ...state,
        exchangeProxies,
        moreInformationEx,
      }
    },
    setSummaryTotal(state, payload) {
      return {
        ...state,
        summaryTotal: payload,
      }
    },
    setListHistApr(state, payload) {
      const listHistApr = payload.sort((item1, item2) => new Date(item1.timeUpdate) - new Date(item2.timeUpdate))
      return {
        ...state,
        listHistApr,
      }
    },
    computeApr(state) {
      const listHistApr = state.listHistApr
      const publicProxies = state.publicProxies
      const historyApr = {}

      if (publicProxies && publicProxies.length && listHistApr && listHistApr.length) {
        for (let i = 0; i < publicProxies.length; i++) {
          const publicProxyName = publicProxies[i].name

          historyApr[publicProxyName] = listHistApr.map((it) => {
            const rexApy = it.rexApy || 0
            const proxiesApr = {
              time: "",
              apr: 0,
              apy: 0,
            }

            const tDate = new Date(it.timeUpdate)
            proxiesApr.time = `${tDate.getDate()}/${tDate.getMonth() + 1}`

            if (it.proxieData?.length) {
              const pData = it.proxieData.find((it) => it.proxies == publicProxyName)
              if (pData) {
                proxiesApr.apr = +parseFloat(parseFloat(pData.apr) * 100).toFixed(2)
                proxiesApr.apy = +parseFloat(parseFloat(pData.apr) * 100 + rexApy).toFixed(2)
              }
            }

            return proxiesApr
          })
        }
      }

      return {
        ...state,
        historyApr,
      }
    },
    updateEOSAmount(state, payload) {
      const { index, apr, eosAmount } = payload
      const moreInformationItem = state.moreInformation[index]
      moreInformationItem.EOSAmount = eosAmount
      moreInformationItem.EOSDaily = parseFloat((eosAmount * parseFloat(apr)) / (100 * 365)).toFixed(3)
      moreInformationItem.EOSAnnually = parseFloat((eosAmount * parseFloat(apr)) / 100).toFixed(3)

      return {
        ...state,
      }
    },
  },
  effects: () => ({
    async queryProxiesTable(_payload, rootState) {
      if (rootState.proxyTable.publicProxies && rootState.proxyTable.publicProxies.length) {
        console.trace(`👀 publicProxies is already fetched!`)
      }
      const t = this

      await Promise.all([
        GetProxiesTable().then((res) => {
          if (res && res.data) {
            t.setPublicProxies(res.data.publicProxies)
            t.setExchangeProxies(res.data.exchangeProxies)
            t.setSummaryTotal(res.data.summary.total)
          }
        }),

        GetHistoryApr().then((res) => {
          if (res && res.code === SUCCESS && res.data) {
            const listHistApr = []
            for (let i = 1; i < MAX_DAY_PER_MONTH; ++i) {
              listHistApr.push(res.data[`${i}`])
            }
            t.setListHistApr(listHistApr)
          }
        }),
      ])

      t.computeApr()
    },
  }),
}
