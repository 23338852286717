import { SessionKit } from '@wharfkit/session'
import { WebRenderer } from '@wharfkit/web-renderer'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginScatter } from '@wharfkit/wallet-plugin-scatter'
import { WalletPluginTokenPocket } from '@wharfkit/wallet-plugin-tokenpocket'

import { CHAIN_API_URL } from 'components/global'

const webRenderer = new WebRenderer()

/**
 * https://wharfkit.com/docs/session-kit/session
 */
export const sessionKit = new SessionKit({
  appName: "Genpool",
  chains: [
    {
      id: process.env.REACT_APP_NETWORK_CHAIN_ID,
      url: CHAIN_API_URL,
    },
  ],
  ui: webRenderer,
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginScatter(),
    new WalletPluginTokenPocket(),
  ],
  allowModify: false,
  broadcast: true,
})
