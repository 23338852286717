// React core
import React, { Component } from 'react';
import { Footer } from 'components';

const TERMS = 'terms'
const POLICY = 'policy'

class Docs extends Component {

    render() {
        return (
            <div className="body">
                <div className="full-container help">
                    <div className="dashboard-row help w-row">
                        <div className="dashboard-cols w-col w-col-6" />
                        <div className="dashboard-cols w-col w-col-6" />
                    </div>
                    <div className="dashboard-row w-row">
                        <div className="dashboard-cols w-col w-col-3 w-col-small-small-stack w-col-medium-6 w-col-tiny-tiny-stack" />
                        <div className="dashboard-cols w-col w-col-3 w-col-small-small-stack w-col-medium-6 w-col-tiny-tiny-stack" />
                        <div className="dashboard-cols w-col w-col-3 w-col-small-small-stack w-col-medium-6 w-col-tiny-tiny-stack" />
                        <div className="dashboard-cols w-col w-col-3 w-col-small-small-stack w-col-medium-6 w-col-tiny-tiny-stack" />
                    </div>
                    <div>
                        <div className="w-container">
                            <div className="w-row">
                                <div className="w-col w-col-4">
                                    <a href={`#${TERMS}`}>Terms and services</a>
                                    <a href={`#${POLICY}`} className="link-13">Privacy policy</a>
                                </div>
                                <div className="w-col w-col-8">
                                    <div id={TERMS} className="w-richtext">
                                        <h1>Terms and Conditions</h1>
                                        <p>Last updated: February 01, 2020</p>
                                        <p>Please read these terms and conditions carefully before using Our Service.</p>
                                        <p>Interpretation and Definitions</p>
                                        <h2><strong>Interpretation</strong></h2>
                                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
                                        <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                                        <h2><strong>Definitions</strong></h2>
                                        <p>For the purposes of these Terms and Conditions:</p>
                                        <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                                        <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to GenerEOS Pty Ltd, .</p>
                                        <p><strong>Country</strong> refers to: New South Wales, Australia</p>
                                        <p><strong>Service</strong> refers to the Website.</p>
                                        <p><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. </p>
                                        <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
                                        <p><strong>Website</strong> refers to Genpool, accessible from genpool.io</p>
                                        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                                        <p><strong>Acknowledgement</strong></p>
                                        <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                                        <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                                        <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                                        <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                                        <p><strong>Links to Other Websites</strong></p>
                                        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                                        <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                                        <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
                                        <p><strong>Termination</strong></p>
                                        <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                                        <p>Upon termination, Your right to use the Service will cease immediately.</p>
                                        <p><strong>Limitation of Liability</strong></p>
                                        <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                                        <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
                                        <p><strong>"AS IS" and "AS AVAILABLE" Disclaimer</strong></p>
                                        <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                                        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                                        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                                        <p><strong>Governing Law</strong></p>
                                        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
                                        <p><strong>Disputes Resolution</strong></p>
                                        <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                                        <p>For European Union (EU) Users</p>
                                        <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
                                        <p>United States Legal Compliance</p>
                                        <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a “terrorist supporting” country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
                                        <h1><strong>Severability and Waiver</strong></h1>
                                        <h2><strong>Severability</strong></h2>
                                        <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                                        <h2><strong>Waiver</strong></h2>
                                        <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
                                        <p><strong>Translation Interpretation</strong></p>
                                        <p>These Terms and Conditions may have been translated if We have made them available to You on our Service.</p>
                                        <p>You agree that the original English text shall prevail in the case of a dispute.</p>
                                        <p><strong>Changes to These Terms and Conditions</strong></p>
                                        <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
                                        <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                                        <p><strong>Contact Us</strong></p>
                                        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                                        <p>By email: contact@genereos.io</p>
                                        <h2>‍</h2>
                                    </div>
                                    <div id={POLICY} className="rich-text-block-2 w-richtext">
                                        <h1>GENPOOL.IO– PRIVACY POLICY</h1>
                                        <p>This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including through our genpool.io (<strong>Site</strong>). In this Privacy Policy <strong>we</strong>, <strong>us</strong> or <strong>our</strong> means GenerEOS Pty Ltd</p>
                                        <p><strong>Personal information</strong></p>
                                        <p>The types of personal information we may collect about you include:&nbsp;</p>
                                        <ul>
                                            <li>&nbsp; &nbsp; information you provide to us through customer surveys;</li>
                                            <li>&nbsp; &nbsp; details of products and services we have provided to you and/or that you have enquired about, and our response to you;</li>
                                            <li>&nbsp; &nbsp; your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour;</li>
                                            <li>&nbsp; &nbsp; information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider;</li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <p>We may collect these types of personal information directly from you or from third parties.</p>
                                        <p><strong>Collection and use of personal information</strong></p>
                                        <p>We may collect, hold, use and disclose personal information for the following purposes:&nbsp;</p>
                                        <ul>
                                            <li>&nbsp; &nbsp; [to enable you to access and use our Site, associated applications and associated social media platforms;</li>
                                            <li>&nbsp; &nbsp; to contact and communicate with you;</li>
                                            <li>&nbsp; &nbsp; for internal record keeping and administrative purposes;</li>
                                            <li>&nbsp; &nbsp; for analytics, market research and business development, including to operate and improve our Site, associated applications and associated social media platforms;</li>
                                            <li>&nbsp; &nbsp; to run competitions and/or offer additional benefits to you;</li>
                                            <li>&nbsp; &nbsp; for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you;</li>
                                            <li>&nbsp; &nbsp; to comply with our legal obligations and resolve any disputes that we may have; and</li>
                                            <li>&nbsp; &nbsp; to consider your employment application.]</li>
                                        </ul>
                                        <p><strong>Disclosure of personal information to third parties</strong></p>
                                        <p>We may disclose personal information to:&nbsp;&nbsp;</p>
                                        <ul>
                                            <li>&nbsp; &nbsp; [third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</li>
                                            <li>&nbsp; &nbsp; our employees, contractors and/or related entities;</li>
                                            <li>&nbsp; &nbsp; our existing or potential agents or business partners;</li>
                                            <li>&nbsp; &nbsp; sponsors or promoters of any competition we run;</li>
                                            <li>&nbsp; &nbsp; anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</li>
                                            <li>&nbsp; &nbsp; third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you. This may include parties located, or that store data, outside of Australia.</li>
                                            <li> third parties to collect and process data, such as (Google Analytics or other relevant businesses). This may include parties that store data outside of Australia.]</li>
                                        </ul>
                                        <p></p>
                                        <p>By providing us with personal information, you consent to the disclosure of your personal information to third parties who reside outside Australia and acknowledge that we are not required to ensure that those third parties comply with Australian privacy laws.&nbsp;</p>
                                        <p><strong>Choice and consent</strong>: Please read this Privacy Policy carefully. By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of this Site or the products and/or services offered on or through it.</p>
                                        <p><strong>Information from third parties</strong>: If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p>
                                        <p><strong>Restrict</strong>: You may choose to restrict the collection or use of your personal information.&nbsp; If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.</p>
                                        <p><strong>Access</strong>: You may request details of the personal information that we hold about you.&nbsp; An administrative fee may be payable for the provision of such information. &nbsp;In certain circumstances, as set out in the <em>Privacy Act 1988</em> (Cth), we may refuse to provide you with personal information that we hold about you.</p>
                                        <p><strong>Correction</strong>: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.</p>
                                        <p><strong>Complaints</strong>: If you believe that we have breached the Australian Privacy Principles and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.</p>
                                        <p><strong>Unsubscribe</strong>: To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</p>
                                        <p><strong>Storage and security</strong></p>
                                        <p>We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</p>
                                        <p>We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that the personal information we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.</p>
                                        <p><strong>Cookies and web beacons</strong></p>
                                        <p>We may use cookies on our Site from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal information, this information may be linked to the data stored in the cookie.</p>
                                        <p>We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.</p>
                                        <p><strong>Links to other websites</strong></p>
                                        <p>Our Site may contain links to other websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.</p>
                                        <p><strong>Amendments</strong></p>
                                        <p>We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our Site. We recommend you check our Site regularly to ensure you are aware of our current Privacy Policy.</p>
                                        <p><strong>For any questions or notices, please contact our Privacy Officer at:</strong></p>
                                        <p>GenerEOS Pty Ltd</p>
                                        <p>Email: contact@genereos.io</p>
                                        <p><strong>Last update</strong>:1/02/2020</p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <h2><br /></h2>
                                        <p>‍</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

// Export a redux connected component
export default (Docs);
