import history from 'services/History';

import iziToast from 'izitoast';
import {
  TOAST,
  ACCOUNT_CONTRACT,
  PROXYS_TABLE,
  BP_TABLE,
  CHAIN_API_URL,
} from "components/global";

import { sessionKit } from "services/SessionKit"

export async function handleError(error, dispatch) {
    if (!error) return

    if (dispatch && dispatch.message) {
        dispatch.message.setErrorMsg(error);
    }

    if (error.isError) {
        iziToast.show({
            message: error.message,
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
    } else {
        try {
            const err = JSON.parse(error);

            if (typeof err === "object") {
                if (err.error?.details?.length) {
                    iziToast.show({
                        message: err.error.details[0].message,
                        progressBar: false,
                        timeout: TOAST.TimeOut,
                        backgroundColor: TOAST.WarningColor,
                        messageSize: TOAST.MessageSize,
                        position: TOAST.Position,
                        messageColor: TOAST.MessageColor,
                    });
                }
            }
        } catch (e) {
            iziToast.show({
                message: error.toString(),
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }
    }
}

export const fetchTable = async (account, scope, table, lower_bound, upper_bound, limit) => {
    try {
        const response = await fetch(`${CHAIN_API_URL}/v1/chain/get_table_rows`, {
            method: 'POST',
            body: JSON.stringify({
                code: account,
                scope: scope,
                table: table,
                lower_bound: lower_bound,
                upper_bound: upper_bound,
                index_position: "1",
                key_type: "i64",
                reverse: "true",
                json: "true",
                limit: limit,
            }),
        });
        const responseJson = await response.json();
        return responseJson.rows || [];

    } catch (error) {
        console.log(error);
        handleError(error)
    }
    return []
}

export const pushTransaction = async (transaction) => {
    let status = false
    try {
        // https://wharfkit.com/docs/session-kit/transact
        const session = await sessionKit.restore()
        const tx = await session.transact(transaction, { broadcast: true, expireSeconds: 60 })
        if (tx) {
            status = true
        }

    } catch (error) {
        console.log( error);
        handleError(error)
    }
    return status
}

export const pushAction = async (account, action, actor, data, permission = 'active') => {
    let status = false
    try {
        const transaction = {
            actions: [{
                account,
                name: action,
                authorization: [{
                    actor,
                    permission
                }],
                data
            }]
        }

        // https://wharfkit.com/docs/session-kit/transact
        const session = await sessionKit.restore()
        const tx = await session.transact(transaction, { broadcast: true, expireSeconds: 60 })
        if (tx) {
            status = true
        }

    } catch (error) {
        console.log( error);
        handleError(error)
    }
    return status
}

export const pushDoubleAction = async (account1, account2, action1, action2, actor, data1, data2, permission = 'active') => {
    let status = false
    try {
        const transaction = {
            actions: [{
                account: account1,
                name: action1,
                authorization: [{
                    actor,
                    permission
                }],
                data: data1,
            },
            {
                account: account2,
                name: action2,
                authorization: [{
                    actor,
                    permission
                }],
                data: data2,
            }]
        }

        // https://wharfkit.com/docs/session-kit/transact
        const session = await sessionKit.restore()
        const tx = await session.transact(transaction, { broadcast: true, expireSeconds: 60 })
        if (tx) {
            status = true
        }

    } catch (error) {
        console.log(error);
        handleError(error)
    }
    return status
}

export const imageExists = (image_url) => {
    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status !== 404;
}

export const CheckTypeOfUser = async (account) => {
    const AccountType = await fetchTable('eosio', 'eosio', 'voters', account, account, 100);

    // console.log('tam AccountType', AccountType)
    if (AccountType && AccountType.length && AccountType[0]) {
        if (AccountType[0].is_proxy) {
            //proxy account
            const IsProxyReg = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, PROXYS_TABLE, account, account, 50);
            // console.log('tam IsProxyReg', IsProxyReg)

            if (IsProxyReg && IsProxyReg.length) {
                //already registered with GenPool, then go to their "Proxy management" dashboard 
                history.push('/dashboard/proxy/' + account)
            } else {
                //new proxy and not registered yet, show them the "Proxy registration" form
                history.push('/register-proxy')
            }
        } else {
            //Voter acoount
            const tmpAccount = AccountType[0].owner
            const IsNormalAccount = await fetchTable('eosio', 'eosio', 'producers', tmpAccount, tmpAccount, 100);
            // console.log('tam IsNormalAccount', IsNormalAccount)

            //Bp Account
            if (IsNormalAccount && IsNormalAccount.length) {
                const IsBpReg = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, account, account, 100);
                //   console.log('tam IsBpReg', IsBpReg)

                if (IsBpReg && IsBpReg.length) {
                    // already registered with GenPool, then go to their page for "Funding" page
                    history.push('/dashboard/bp/' + account)
                } else {
                    //new BP, show them the "BP registration" form
                    history.push('/dashboard/bp')
                }
                //normal account
            } else {
                //don't exits in table => normal account => do nothing
            }
        }
    } else {
        //normal account
        //don't exits in table => normal account => do nothing
    }
}

export const GetTokenInfo = async (account) => {
    const symbol = "EOS"
    const code = "eosio.token"
    try {
        const response = await fetch(`${CHAIN_API_URL}/v1/chain/get_currency_balance`, {
            method: 'POST',
            body: JSON.stringify({
                code: code,
                account: account,
                symbol: symbol,
            }),
        });

        const TokenValue = await response.json();
        // console.log('tam token', TokenValue, account)
        return TokenValue;
    } catch (error) {
        console.log(error);
        handleError(error)
    }

}

export const GetProxiesTable = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/getGenPoolProxies`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}

export const GetReward = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/get_reward`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}

export const GetHistoryApr = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/get_history_apr`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}
