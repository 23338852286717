// React core
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer } from 'components'
import { Link } from "react-router-dom"

import iziToast from 'izitoast'
import { TOAST, ACCOUNT_CONTRACT, BP_TABLE, PROXYS_TABLE } from 'components/global'

import { multilanguage, loadLanguages } from 'services/multilanguage'

import { fetchTable, pushAction, pushDoubleAction } from 'services/ApiService'

import spin from 'assets/images/spiner.svg'
import info_topup from 'assets/images/info.svg'
import sending from 'assets/images/sending.svg'

const MAX_VOTE = 30
const TAB1 = 1
const TAB2 = 2
const TAB3 = 3

const TAB_ID_1 = /\/dashboard\/proxy\/[a-zA-Z0–9]+\/fund-account/
const TAB_ID_2 = /\/dashboard\/proxy\/[a-zA-Z0–9]+\/refund/
const TAB_ID_3 = /\/dashboard\/proxy\/[a-zA-Z0–9]+\/set-apr/

class ProxyInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proxyName: '',
            BpInternalTable: [],
            ListBpInternal: [],
            ListBpVote: [],
            IsLoadingProxyTable: false,

            NumberOfSelect: 0,
            whiteList: [],
            NumberOfWhiteList: 0,

            IsAutoVote: false,
            isSelectAllWhiteList: false,

            tabId: TAB1,
            depositAmount: 0,
            percentAmount: 0,

            isSendingActionList: {
                vote: false,
                whiteListBp: false,
                depositFundAccount: false,
                setAPR: false,
            }
        }
    }

    componentDidMount = async () => {
        this.loadLanguages();
        this.loadBpInternalTable();

        if(TAB_ID_1.test(this.props.location.pathname)){
            this.setState({
                tabId: TAB1,
            })
        }else if(TAB_ID_2.test(this.props.location.pathname)){
            this.setState({
                tabId: TAB2,
            })
        }else if(TAB_ID_3.test(this.props.location.pathname)){
            this.setState({
                tabId: TAB3,
            })
        }else{
            this.setState({
                tabId: TAB1,
            })
        }
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    loadBpInternalTable = async () => {
        if (this.props.match && this.props.match.params) {
            this.setState({
                IsLoadingProxyTable: true,
            })

            //get proxy name of current page
            const proxyName = this.props.match.params.proxyId

            this.setState({
                proxyName: proxyName,
            })
            
            const proxyTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, PROXYS_TABLE, proxyName, proxyName, 50)
            // console.log('tam proxy table', proxyTable)

            if (proxyTable && proxyTable[0] && proxyTable[0].white_list && proxyTable[0].white_list.length) {
                this.setState({
                    whiteList: proxyTable[0].white_list
                })
            }

            const voteList = await fetchTable('eosio', 'eosio', "voters", proxyName, proxyName, 50);
            if (voteList && voteList.length && voteList[0] && voteList[0].producers && voteList[0].producers.length) {
                this.setState({
                    ListBpVote: voteList[0].producers,
                })
            }

            //read bp table from contract
            const BpTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, "", "", 100);
            // console.log('tam BpTable', BpTable)

            let tmpTable = []

            let NumberSelect = 0
            let NumberWhiteList = 0

            //calculation bp internal table
            if (BpTable && BpTable.length) {
                for (let i = 0; i < BpTable.length; ++i) {
                    if (BpTable[i].proxy_donate && BpTable[i].proxy_donate.length) {
                        let tempBpInternal = {
                            account: '',
                            fund: '',
                            donate: '',
                            status: '',
                            weblink: '',
                            isVote: '',
                            isInWhiteList: '',
                        }
                        let isVote = false
                        let totalDonate = 0
                        for (let ii = 0; ii < BpTable[i].proxy_donate.length; ++ii) {
                            if (proxyName === BpTable[i].proxy_donate[ii].key) {
                                isVote = true
                                totalDonate = totalDonate + parseFloat(BpTable[i].proxy_donate[ii].value)
                            }
                        }
                        if (isVote) {
                            tempBpInternal.account = BpTable[i].account
                            tempBpInternal.fund = BpTable[i].fund
                            tempBpInternal.donate = totalDonate

                            tempBpInternal.status = BpTable[i].status
                            tempBpInternal.weblink = BpTable[i].weblink

                            const IsInList = voteList[0].producers.includes(BpTable[i].account)
                            tempBpInternal.isVote = IsInList

                            if (IsInList) {
                                ++NumberSelect
                            }

                            let isInWhiteList = false

                            if (proxyTable && proxyTable[0] && proxyTable[0].white_list && proxyTable[0].white_list.length) {
                                isInWhiteList = proxyTable[0].white_list.includes(BpTable[i].account)
                                if (isInWhiteList) {
                                    ++NumberWhiteList
                                }
                            }

                            tempBpInternal.isInWhiteList = isInWhiteList

                            tmpTable.push(tempBpInternal)
                        }
                    }
                }
            }

            tmpTable = tmpTable.sort((a, b) => (parseFloat(a.fund) < parseFloat(b.fund) ? 1 : -1))

            this.setState({
                BpInternalTable: tmpTable,
                IsLoadingProxyTable: false,
                NumberOfSelect: NumberSelect,
                NumberOfWhiteList: NumberWhiteList
            })
        }
    }

    updateStatusActionList = (action, isSending) => {
        let actionList = this.state.isSendingActionList
        
        actionList[`${action}`] = isSending
        this.setState({
            isSendingActionList: actionList
        })
    }

    handleVote = async () => {
        // this.setState({
        //   IsLoading: true,
        // })
        if (this.props.wallet?.accountName) {
            this.updateStatusActionList('vote', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            let newProducer = []

            for (let i = 0; i < this.state.BpInternalTable.length; ++i) {
                if (this.state.BpInternalTable[i].isVote) {
                    newProducer.push(this.state.BpInternalTable[i].account)
                }
            }

            // console.log('tam new producer', newProducer)
            //sort new list from a to z
            newProducer = newProducer.sort((a, b) => (a > b ? 1 : -1))

            const account = 'eosio'
            const action = 'voteproducer'

            const data = {
                voter: actor,
                proxy: '',
                producers: newProducer,
            }
            const voteproducer = await pushAction(account, action, actor, data, permission)
            if (voteproducer) {
                iziToast.show({
                    message: this.props.strings["VoteOke"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
                this.loadBpInternalTable()
            }
            // console.log('tam voteproducer', voteproducer)
            this.updateStatusActionList('vote', false)
        } else {
            this.props.login()

        }
        // this.setState({
        //   IsLoading: false,
        // })
    }

    handleChangeSelect = (e) => {
        const value = e.target.value ? e.target.value : null
        const IsCheck = e.target.checked ? e.target.checked : false

        let count = this.state.NumberOfSelect
        if (IsCheck) {
            ++count
        } else {
            --count
        }

        if (count > MAX_VOTE) {
            iziToast.show({
                message: this.props.strings["inforMax"] + MAX_VOTE + this.props.strings["inforDetail"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else {
            var tmpTable = this.state.BpInternalTable.slice();
            for (let i = 0; i < tmpTable.length; ++i) {
                if (value === tmpTable[i].account) {
                    tmpTable[i].isVote = IsCheck
                    break
                }
            }

            this.setState({
                BpInternalTable: tmpTable,
                NumberOfSelect: count,
            })
        }
    }

    handleChangeWhiteList = (e) => {
        const value = e.target.value ? e.target.value : null
        const IsCheck = e.target.checked ? e.target.checked : false

        let count = this.state.NumberOfWhiteList

        if (IsCheck) {
            ++count
        } else {
            --count
        }

        var tmpTable = this.state.BpInternalTable.slice();
        for (let i = 0; i < tmpTable.length; ++i) {
            if (value === tmpTable[i].account) {
                tmpTable[i].isInWhiteList = IsCheck
                break
            }
        }

        this.setState({
            BpInternalTable: tmpTable,
            NumberOfWhiteList: count,
            isSelectAllWhiteList: count === tmpTable.length ? true : false,
        })
    }

    handleEnWhiteList = async () => {
        if (this.props.wallet?.accountName) {
            this.updateStatusActionList('whiteListBp', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            let newWhiteList = []

            for (let i = 0; i < this.state.BpInternalTable.length; ++i) {
                if (this.state.BpInternalTable[i].isInWhiteList) {
                    newWhiteList.push(this.state.BpInternalTable[i].account)
                }
            }

            //sort new list from a to z
            newWhiteList = newWhiteList.sort((a, b) => (a > b ? 1 : -1))

            const account = ACCOUNT_CONTRACT
            const action = 'addwhitelist'

            const data = {
                proxy: this.state.proxyName,
                bps: newWhiteList,
            }
            const addWhiteList = await pushAction(account, action, actor, data, permission)
            if (addWhiteList) {
                iziToast.show({
                    message: this.props.strings["updatedList"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
                this.loadBpInternalTable()
            }
            this.updateStatusActionList('whiteListBp', false)

        } else {
            this.props.login()
        }
    }

    rowInternals = () => {
        if (this.state.BpInternalTable && this.state.BpInternalTable.length) {
            let rows = []
            for (let i = 0; i < this.state.BpInternalTable.length; ++i) {
                rows.push(
                    <tr key={i} className="row100-body">
                        <td className="column1-regBp">
                            <div className="column-text">{this.state.BpInternalTable[i].account}</div>
                        </td>
                        <td className="column2-regBp">
                            <div className="column-text">{this.state.BpInternalTable[i].fund}</div>
                        </td>
                        <td className="column3-regBp">
                            <div className="column-text">{this.state.BpInternalTable[i].donate} EOS</div>
                        </td>
                        <td className="column4-regBp">
                            <div className="column-text">{this.state.BpInternalTable[i].weblink}</div>
                        </td>
                        <td className="column5-regBp">
                            <label key={i} className="checkbox">
                                <input type="checkbox" checked={`${this.state.BpInternalTable[i].isVote ? 'checked' : ''}`} onChange={this.handleChangeSelect} value={this.state.BpInternalTable[i].account} />
                                <span></span>
                            </label>
                        </td>
                        <td className="column6-regBp rows">
                            <label key={i} className="checkbox">
                                <input type="checkbox" checked={`${this.state.BpInternalTable[i].isInWhiteList ? 'checked' : ''}`} onChange={this.handleChangeWhiteList} value={this.state.BpInternalTable[i].account} />
                                <span></span>
                            </label>
                        </td>
                        <td className="column7-regBp">

                        </td>
                    </tr>
                )
            }
            return rows
        }
    }

    updateAutoVote = async () => {
        if (this.state.proxyAccount === '') {
            iziToast.show({
                message: this.props.strings["InvalidProxyAccount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (this.props.wallet?.accountName) {
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            const account = 'eosio'

            const action = 'updateauth'
            const action2 = 'linkauth'

            const auth = {
                threshold: 1,
                keys: [],
                waits: [],
                accounts: [{
                    weight: 1,
                    permission: {
                        actor: 'genereospool',
                        permission: 'eosio.code'
                    }
                }]
            }

            const data = {
                account: actor,
                auth: auth,
                parent: 'active',
                permission: 'vote',
            }

            const data2 = {
                account: actor,
                code: 'eosio',
                requirement: 'vote',
                type: 'voteproducer',
            }

            const regAuth = await pushDoubleAction(account, account, action, action2, actor, data, data2, permission)
            // console.log('tam regAsProxy lower', regAsProxy)
            if (regAuth && regAuth.transaction_id) {
                iziToast.show({
                    message: this.props.strings["RegOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
                this.setState({
                    IsAutoVote: true,
                })
            }

        } else {
            this.props.login()
        }
    }

    selectTab = (id) => () => {
        this.setState({
            tabId: id
        })
    }

    updateDepositAmount = (depositAmount) => {
        this.setState({
            depositAmount: depositAmount.target.value ? depositAmount.target.value : 0,
        })
    }

    updatePpercentAmount = (percentAmount) => {
        this.setState({
            percentAmount: percentAmount.target.value ? percentAmount.target.value : 0,
        })
    }

    handleSelectAllWhiteList = () => {
        var tmpTable = this.state.BpInternalTable.slice();
        for (let i = 0; i < tmpTable.length; ++i) {
                tmpTable[i].isInWhiteList = !this.state.isSelectAllWhiteList
        }

        this.setState({
            BpInternalTable: tmpTable,
            NumberOfWhiteList: !this.state.isSelectAllWhiteList ? tmpTable.length : 0,
            isSelectAllWhiteList: !this.state.isSelectAllWhiteList,
        })
    }

    handleDepositProxy = async () => {
        if(this.state.depositAmount === 0){
            iziToast.show({
                message: this.props.strings["InvalidAmount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.props.wallet?.accountName){
            //handle proxy deposit
            this.updateStatusActionList('depositFundAccount', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission
      
            const account = 'eosio.token'
            const action = 'transfer'

            const quantity = parseFloat(this.state.depositAmount).toFixed(4) + ' EOS'

            const data = {
                from: actor,
                to: ACCOUNT_CONTRACT,
                quantity: quantity,
                memo: this.state.proxyName,
            }
           
            const deposit = await pushAction(account, action, actor, data, permission)
            // console.log('tam deposit', deposit)
            if(deposit){
                iziToast.show({
                    message: this.props.strings["DepositOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusActionList('depositFundAccount', false)

        }else{
            this.props.login()
        }
    }

    handleClaim = async () => {
        iziToast.show({
            message: this.props.strings["Comming_Soon"],
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.SucessColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
    }

    handleSetAPR = async () => {
        if(this.state.percentAmount <= 0 || this.state.percentAmount > 10){
            iziToast.show({
                message: this.props.strings["InvalidAmount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.props.wallet?.accountName){
            //handle proxy deposit
            this.updateStatusActionList('setAPR', true)
            const proxyTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, PROXYS_TABLE, this.state.proxyName, this.state.proxyName, 50)
            // console.log('tam proxyTable', proxyTable)
            if(proxyTable && proxyTable.length){
                const reward_percent = parseFloat(proxyTable[0].reward_percent)
                const proxy_fee_percent = parseFloat(proxyTable[0].proxy_fee_percent)

                const actor = this.props.wallet.accountName
                const permission = this.props.wallet.permission
          
                const account = ACCOUNT_CONTRACT
                const action = 'regproxy'    
    
                const data = {
                    proxy: this.state.proxyName,
                    reward_percent: reward_percent,
                    proxy_fee_percent: proxy_fee_percent,
                    target_apr: parseFloat(this.state.percentAmount / 100),
                    referral_percent: 0,
                    transfer_reward: false,
                }
               
                const setAPR = await pushAction(account, action, actor, data, permission)
                if(setAPR){
                    iziToast.show({
                        message: this.props.strings["Settings_saved"],
                        progressBar: false,
                        timeout: TOAST.TimeOut,
                        backgroundColor: TOAST.SucessColor,
                        messageSize: TOAST.MessageSize,
                        position: TOAST.Position,
                        messageColor: TOAST.MessageColor,
                    });
                }
                this.updateStatusActionList('setAPR', false)

            }else{
                iziToast.show({
                    message: this.props.strings["invalid_proxy"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }else{
            this.props.login()
        }
    }

    render() {
        const { strings } = this.props

        return (
            <div className="body">
                <div className="section-6">
                    <div className="div-block-19">
                        <div id="RewardProxy" className="div-block-11-copy">
                            <div className="div-block-14">
                                <h1 className="heading-5 _22">{strings["Registered"]} BPs:   {this.state.proxyName}</h1>
                                <div className="wrap-label">
                                    <div className="label-information">
                                        <div className="toggle-group" >
                                            <span className="tooltiptext-proxy">{strings["help_tooltip"]}</span>
                                            <input type="checkbox" id="on-off-switch" value={this.state.IsAutoVote} checked={`${this.state.IsAutoVote ? 'checked' : ''}`} />
                                            <label htmlFor="on-off-switch" className="proxy-label"> <img src={info_topup} className="img-info-topup" alt="" /> {strings["auto_vote"]} </label>
                                            <div className="onoffswitch pull-right" aria-hidden="true" onClick={this.updateAutoVote}>
                                                <div className="onoffswitch-label">
                                                    <div className="onoffswitch-inner" />
                                                    <div className="onoffswitch-switch" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-duration-in={300} data-duration-out={100} className="tabs-3 w-tabs proxy-copy">
                        <div className="tabs-menu-4 w-tab-menu">
                            <Link to={`/dashboard/proxy/${this.state.proxyName}/fund-account`} data-w-tab="Tab 1" onClick={this.selectTab(TAB1)}
                                className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB1 ? 'w--current' : ''}`}>
                                <div className="text-block-18">{strings["FundAccount"]}</div>
                            </Link>
                            <Link to={`/dashboard/proxy/${this.state.proxyName}/refund`} data-w-tab="Tab 2" onClick={this.selectTab(TAB2)}
                                className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB2 ? 'w--current' : ''}`}>
                                <div className="text-block-29">{strings["Refund"]}</div>
                            </Link>
                            <Link to={`/dashboard/proxy/${this.state.proxyName}/set-apr`} data-w-tab="Tab 3" onClick={this.selectTab(TAB3)}
                                className={`tab-link-tab-2 side w-inline-block w-tab-link ${this.state.tabId === TAB3 ? 'w--current' : ''}`}>
                                <div className="text-block-30">{strings['setting']}</div>
                            </Link>
                        </div>
                        <div className="tabs-content-4 w-tab-content">
                            <div data-w-tab="Tab 1" className={`tab-pane-tab-1 w-tab-pane ${this.state.tabId === TAB1 ? 'w--tab-active' : ''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["FundProxy"]}</h2>
                                    <div className="form-block-2 w-form">
                                        <form id="email-form2" name="email-form" data-name="Email Form">
                                            <input type="number" className="text-field big left w-input" maxLength={256} placeholder={this.props.strings["eosAmount"]} id="name-2"
                                                value={this.state.depositAmount || ''} onChange={this.updateDepositAmount} />
                                        </form>
                                        <div className="w-form-done">
                                            <div>{strings["FormDone"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["Oops"]}</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info left">
                                        {strings["Info1"]}
                                    </div>
                                    <div className="button4 w-button-edit proxyInterBtn-3" onClick={this.handleDepositProxy}>
                                        {
                                            this.state.isSendingActionList.depositFundAccount ? 
                                                <img src={sending} alt="" className="image-bpInter-3"/>
                                                : <div>{strings["Deposit"]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="Tab 2" className={`tab-pane-tab-1 w-tab-pane ${this.state.tabId === TAB2 ? 'w--tab-active' : ''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["ClaimFunds"]}</h2>
                                    <div className="form-block-2 w-form">
                                        <div className="w-form-done">
                                            <div>{strings["FormDone"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["Oops"]}</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info left">
                                        {strings["Info2"]}‍
                                    </div>
                                    <div href="#" className="button4 w-button-edit" onClick={this.handleClaim}>{strings["Claim"]}‍</div>
                                </div>
                            </div>
                            <div data-w-tab="Tab 3" className={`tab-pane-tab-1 w-tab-pane ${this.state.tabId === TAB3 ? 'w--tab-active' : ''}`}>
                                <div className="div-block-12">
                                    <h2 className="heading-5 sub2 nay">{strings["SetAPR_Title"]}</h2>
                                    <div className="form-block-2 w-form">
                                        <form id="email-form2" name="email-form" data-name="Email Form">
                                            <input type="number" className="text-field big left w-input" maxLength={256} placeholder={this.props.strings["AmountPercent"]} id="name-2"
                                                value={this.state.percentAmount || ''} onChange={this.updatePpercentAmount} />
                                        </form>
                                        <div className="w-form-done">
                                            <div>{strings["FormDone"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["Oops"]}</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info left">
                                        {strings["InfoAPR"]}
                                    </div>
                                    <div className="button4 w-button-edit proxyInterBtn-4" onClick={this.handleSetAPR}>
                                        {
                                            this.state.isSendingActionList.setAPR ? 
                                                <img src={sending} alt="" className="image-bpInter-4"/>
                                                : <div className="setBtn">{strings["set"]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="proxyinternal-copy">
                        <div className="container-table100">
                            <div className="table100">
                                <table>
                                    <thead>
                                        <tr className="row100-head">
                                            <th className="column1-regBp">
                                                <div className="col-text">{strings["Name"]}</div>
                                            </th>
                                            <th className="column2-regBp">
                                                <div className="col-text">{strings["Funds"]}</div>
                                            </th>
                                            <th className="column3-regBp">
                                                <div className="col-text">{strings["Donate"]}</div>
                                            </th>
                                            <th className="column4-regBp">
                                                <div className="col-text">{strings["Website"]}</div>
                                            </th>
                                            <th className="column5-regBp">
                                                {/* <div className="button-4 w-button-edit" onClick={this.handleVote}>{`${this.props.strings["Vote"]}(${this.state.NumberOfSelect}/${MAX_VOTE})`}</div> */}
                                                <div className="button-4 w-button-edit proxyInterBtn-1" onClick={this.handleVote}>
                                                    {
                                                        this.state.isSendingActionList.vote ? 
                                                            <img src={sending} alt="" className="image-bpInter-1"/>
                                                             : <div>{`${this.props.strings["Vote"]}(${this.state.NumberOfSelect}/${MAX_VOTE})`}</div> 
                                                    }
                                                </div>
                                            </th>
                                            <th className="column6-regBp">
                                                {/* <div className="button-4 w-button-edit" onClick={this.handleEnWhiteList}>{`${this.props.strings["WhiteList"]}(${this.state.NumberOfWhiteList})`}</div> */}
                                                    <label className="checkbox">
                                                        <input type="checkbox" checked={`${this.state.isSelectAllWhiteList ? 'checked' : ''}`} value={this.state.isSelectAllWhiteList} onChange={this.handleSelectAllWhiteList}/>
                                                        <span></span>
                                                    </label>
                                                <div className="button-4 w-button-edit proxyInterBtn-2" onClick={this.handleEnWhiteList}>
                                                    {
                                                        this.state.isSendingActionList.whiteListBp ? 
                                                        <img src={sending} alt="" className="image-bpInter-2"/>
                                                            : <div>{`${this.props.strings["WhiteList"]}(${this.state.NumberOfWhiteList})`}</div>
                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.rowInternals()}

                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.IsLoadingProxyTable ?
                                    <div className="rowrewarddiv-loading">
                                        <img src={spin} width={100} alt="" className="image-12" />
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...dispatch.wallet,
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(ProxyInternal));
