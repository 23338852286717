// Notification setting
export const TOAST = {
  SucessColor: "#359E0B",
  WarningColor: "#FFB442",
  ErrorColor: "#FF6970",
  InfoColor: "#157CCF",

  MessageColor: "#FFFFFF",
  MessageSize: "18",
  TimeOut: 7000,

  Position: "bottomRight",
}

//for smart contract
export const ACCOUNT_CONTRACT = "genereospool"
export const BP_TABLE = "v3.bps"
export const PROXYS_TABLE = "v5.proxies"
export const REFERRAL_TABLE = "v2.referrals"

//for country language
export const EN = "en"
export const CH = "ch"
export const KR = "kr"

export const ENTER = 13
export const ESC = 27
export const HOME = 36

export const PROXY_ACCOUNT = 1
export const BP_ACCOUNT = 2
export const NORMAL_ACCOUNT = 3

export const SUCCESS = 200

export const CHAIN_API_URL = process.env.REACT_APP_CHAIN_API_URL
