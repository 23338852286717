import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer, Spin } from 'components'
import CountUp from 'react-countup'
import { multilanguage, loadLanguages } from 'services/multilanguage'
import { NavLink, Switch, Route } from "react-router-dom"
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, Label
  } from 'recharts'

import iziToast from 'izitoast'
import { TOAST, ACCOUNT_CONTRACT, SUCCESS } from 'components/global'
import NumberFormat from 'react-number-format'

import { pushDoubleAction, GetProxiesTable, GetReward, GetHistoryApr } from 'services/ApiService'

import EOS_Main100 from 'assets/images/EOS-Main100.svg'
// import mJafI from 'assets/images/mJafI-2.png'
import mJafI from 'assets/images/meetone_logo.png'
import msbg3 from 'assets/images/msbg3-2.png'
import mYxkZ from 'assets/images/mYxkZ-2.png'
import mi5Pz from 'assets/images/mi5Pz-2.png'
import Logo_06nJ from 'assets/images/06nJ-2.png'
import mkDyx from 'assets/images/eosnation.png'
import Logo_2Mk3 from 'assets/images/2Mk3-2.png'
import Cmvk from 'assets/images/Cmvk-2.png'
import Logo_2Gv6 from 'assets/images/2Gv6-2.png'
import lvAW from 'assets/images/lvAW-2.png'
import m76HF from 'assets/images/m76HF-2.png'
import mitVk from 'assets/images/mitVk-2.png'
import mteG8 from 'assets/images/mteG8-2.png'
import mc4Hb from 'assets/images/mc4Hb-2.png'
import m3doB from 'assets/images/m3doB-2.png'
import spin from 'assets/images/spiner.svg'
import info_topup from 'assets/images/info.svg'
import { GenpoolProxy } from './GenpoolProxy'

const GENPOOL_PROXY_PATH = '/genpool-proxy'
const PUBLIC_PATH = '/public-proxy'
const EXCHANGE_PATH = '/exchange-proxy'

// const NUMBER_OF_EXCHANGE_ACCOUNT = 15
const PATTERN_REF_LINK = 'ref'
const INCOMING_DAY = 27
const REMAIN_DAY = 6

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referralUser: '',

            incomingDays: new Date().getDate(),
            isComingDays: false,
            eosTotalReward: 0,
            genBurn: 0,

        }
    }

    componentDidMount = async () => {
        const { dispatchProxyTable } = this.props
        this.loadLanguages();

        dispatchProxyTable.queryProxiesTable()

        this.initializeReferralUser();
        this.initializeInforReward();
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    getParameterFromUrl = (url, parm) => {
        var re = new RegExp(".*[?&]" + parm + "=([^&]+)(&|$)");
        var match = url.match(re);
        return (match ? match[1] : "");
    }

    initializeInforReward = async () => {
        const now = new Date().getDate()
        let incomingDays = INCOMING_DAY - now
        if(incomingDays < 0){
            incomingDays = INCOMING_DAY + new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate() - now
        }

        this.setState({
            incomingDays: incomingDays,
            isComingDays: incomingDays < REMAIN_DAY ? true : false
        })

        const reward = await GetReward()

        if(reward && reward.code === SUCCESS && reward.data){
            this.setState({
                genBurn: parseFloat(reward.data.GEN),
                eosTotalReward: parseFloat(reward.data.EOS)
            })
        }
    }

    initializeReferralUser = () => {
        const refLink = window.document.location.href
        let ref = '';

        if (refLink) {
            ref = this.getParameterFromUrl(refLink, PATTERN_REF_LINK)
            // console.log('tam Id', ref)
            this.setState({
                referralUser: ref
            })
        }
    }

    componentWillUnmount = () => {

    }

    handleVoteEarn = () => {
        window.scrollTo(0, this.myRef.offsetTop)
    }

    shortString = (string) => {
        let ret = ''
        if (string.length > 13) {
            ret = string.substring(0, 13) + '...'

        } else {
            ret = string

        }
        return ret
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    render() {
        const { strings, proxyTableState, dispatchProxyTable, loadingProxyTable, wallet: {accountName}, login, fetchBalance, handleStakeFn, queryUnstaking } = this.props

        const proxyTable = proxyTableState.publicProxies?.map((data, index) => (
            [
                <tr key={index + 1} className="row100-body">
                    <td className="column2">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3">
                        <img src={data.logo_256} width={32} height={32} alt="" className="image-12" />
                        <div className="column-text name">{data.name}</div>
                    </td>
                    <td className="column4">
                        <div className="column-text">{data.stakeEOS.toLocaleString('en')}</div>
                    </td>
                    <td className="column5">
                        <div className="column-text">{data.frequency}</div>
                    </td>

                    {/* <td className="column8">
                        <div className="column-text">{data.APR}</div>
                    </td> */}

                    <td className="column9">
                        <div className="infobutton mainInfoBtn" onClick={() => dispatchProxyTable.showMoreInformation(index)}>
                        {strings["Info"]}{' '}
                        { proxyTableState.moreInformation[index].isShow ? <span>&#x2227;</span> : <span>&#x2228;</span> }
                        </div>

                        <div className="link-11 mainVoteBtn" onClick={async (evt) => {
                            evt.preventDefault()
                            await handleStakeFn({
                                proxyName: data.name,
                                stakeAmt: '',
                                stakeRex: false,
                                referralUser: this.state.referralUser,
                            })
                            await Promise.all([fetchBalance(accountName), queryUnstaking()])
                        }}>
                            {
                                false ?
                                    <img src={sending} alt="" className="image-main-1" />
                                    : <div>{strings["Vote"]}</div>
                            }
                        </div>
                    </td>
                </tr>,
                <tr key={-index - 1} className={`row-addition ${proxyTableState.moreInformation[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colSpan="8">
                        {
                            <div className="area-char-main">
                                <div className="area-char-main-title">
                                    <div className="area-char-main-title-text">{strings["Genpool Income Trend"]}</div>
                                    <div className="area-char-main-title-text">
                                        {strings["Highest APR Yday"]}{': '}
                                        <b>{Math.max(...(proxyTableState.historyApr[`${data.name}`]?.map(it => +it.apr) || [0]))} %</b>
                                    </div>
                                </div>
                                <AreaChart width={700} height={280} data={proxyTableState.historyApr[`${data.name}`]}
                                    margin={{ top: 10, right: 10, left: 10, bottom: 30, }} >
                                    <XAxis dataKey="time">
                                        <Label value={strings["Date"]} offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: strings["Yield"], angle: -90, position: 'insideLeft' }} />

                                    <Tooltip />
                                    <Area type="monotone" dataKey="apr" stroke="#2cbae0" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </div>
                        }
                    </td>
                </tr>,
                <tr key={-index * 3} className={`row-addition ${proxyTableState.moreInformation[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colSpan="2">
                        <div className="row-addition-text title">Details</div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Account: `}</div>
                            <div className="row-addition-text2">{data.name}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied EOS: `}</div>
                            <div className="row-addition-text2">{parseFloat(data.stakeEOS).toLocaleString('en')}</div></div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Candidate Votes: `}</div>
                            <div className="row-addition-text2">{data.candidateVote}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied Accounts: `}</div>
                            <div className="row-addition-text2">{data.proxiesAccount}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Rewards: `}</div>
                            <div className="row-addition-text2">{data.rewards}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxy Fee: `}</div>
                            <div className="row-addition-text2">{data.proxyFee}</div>
                        </div>
                        {
                            (data.website && data.website !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Website: `}</div>
                                    <a href={data.website} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.website)}</a></div>
                                : null
                        }
                        {
                            (data.steemit && data.steemit !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Steemit: `}</div>
                                    <a href={`https://steemit.com/@${data.steemit}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.steemit)}</a></div>
                                : null
                        }
                        {
                            (data.telegram && data.telegram !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Telegram: `}</div>
                                    <a href={`https://t.me/${data.telegram}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.telegram)}</a></div>
                                : null
                        }
                        {
                            (data.twitter && data.twitter !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Twitter: `}</div>
                                    <a href={`https://twitter.com/${data.twitter}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.twitter)}</a></div>
                                : null
                        }
                    </td>
                    <td className="row-addition2" colSpan="3">
                        <div className="row-addition-text title">Voting Philosophy</div>
                        <div className="row-addition-text paragraph">
                            {
                                data.votePhilosophy ? data.votePhilosophy.split('\n').map((items, index) => {
                                    return (
                                        <p className="paragraph-text" key={index}>{items}</p>
                                    )
                                }) : null
                            }
                        </div>
                        {/* <div className="row-addition-text title">Background</div>
                        <div className="row-addition-text paragraph">
                            {
                                data.backgroud ? data.backgroud.split('\n').map((items, index) => {
                                    return (
                                        <p className="paragraph-text" key={index}>{items}</p>
                                    )
                                }) : null
                            }
                        </div> */}
                    </td>
                    <td className="row-addition4">
                        <div className="row-addition-text title">Estimated Revenue</div>
                        <div className="row-addition-text paragraph">
                            <NumberFormat thousandSeparator={true} type="text" className="text-field w-input main-page" suffix={' EOS'} maxLength={15} onInput={this.maxLengthCheck}
                                value={proxyTableState.moreInformation[index].EOSAmount || ''} onValueChange={(evt) => dispatchProxyTable.updateEOSAmount({index, apr: data.APR, eosAmount: evt.value})} placeholder={"Enter EOS Amount"} />
                        </div>
                        <div className="row-addition-text paragraph apr">Daily: {proxyTableState.moreInformation[index].EOSDaily} EOS</div>
                        <div className="row-addition-text paragraph apr">Annually: {proxyTableState.moreInformation[index].EOSAnnually} EOS</div>
                    </td>
                </tr>
            ]
        ))

        const ExchangeProxyTable = proxyTableState.exchangeProxies?.map((data, index) => (
            [
                <tr key={index + 1} className="row100-body">
                    <td className="column2">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3 exchange">
                        <img src={data.logo_256} width={32} height={32} alt="" className="image-12 exchange" />
                        <div className="column-text name">{data.name}</div>
                    </td>
                    <td className="column4 exchange">
                        <div className="column-text">{data.stakeEOS.toLocaleString('en')}</div>
                    </td>
                    <td className="column5 exchange">
                        <div className="column-text">{data.frequency}</div>
                    </td>
                    <td className="column6 exchange">
                        <div className="column-text">{data.rewards}</div>
                    </td>
                </tr>,
                <tr key={-index} className={`row-addition ${proxyTableState.moreInformationEx[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colSpan="6">
                        <div className="row-addition-text title">Details</div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Account: `}</div>
                            <div className="row-addition-text2">{data.name}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied EOS: `}</div>
                            <div className="row-addition-text2">{parseFloat(data.stakeEOS).toLocaleString('en')}</div></div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Candidate Votes: `}</div>
                            <div className="row-addition-text2">{data.candidateVote}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied Accounts: `}</div>
                            <div className="row-addition-text2">{data.proxiesAccount}</div>
                        </div>
                        {
                            (data.website && data.website !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Website: `}</div>
                                    <a href={data.website} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.website)}</a></div>
                                : null
                        }
                        {
                            (data.steemit && data.steemit !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Steemit: `}</div>
                                    <a href={`https://steemit.com/@${data.steemit}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.steemit)}</a></div>
                                : null
                        }
                        {
                            (data.telegram && data.telegram !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Telegram: `}</div>
                                    <a href={`https://t.me/${data.telegram}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.telegram)}</a></div>
                                : null
                        }
                        {
                            (data.twitter && data.twitter !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Twitter: `}</div>
                                    <a href={`https://twitter.com/${data.twitter}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.twitter)}</a></div>
                                : null
                        }
                    </td>
                </tr>
            ]
        ))

        return (
            <div className="body">
                <div className="main-content">
                    <div className="div-block-5">
                        <div className="text-block-13 sub3" onClick={() => { if (!accountName) login() }}>
                            <CountUp duration={2} end={proxyTableState.summaryTotal} separator=","/> {strings["EOSvote"]}
                        </div>
                        <h1 className="heading-5" >{strings["EOSreturn"]}</h1>
                        <div className="text-block-13" >
                            {strings["EOSVoteRewards"]}
                        </div>
                        <div className="button-3 w-button-edit" onClick={this.handleVoteEarn}>{strings["VoteAndEarn"]}</div>
                    </div>
                    <a className="div-block-5" href="https://www.youtube.com/watch?v=s_H4Onwu2-A&feature=youtu.be" rel="noopener noreferrer" target="_blank" >
                        <img src={EOS_Main100} alt="" className="image-6" />
                    </a>
                </div>
                {/* <div className="main-content-infor">
                    <div className="main-content-infor-1">
                        <div className="main-content-infor-2"><CountUp duration={2} end={this.state.eosTotalReward} separator=","/> EOS</div>
                        <div className="main-content-infor-3">{strings["Total EOS Reward"]}</div>
                    </div>
                    <div className="main-content-infor-1">
                        <div className="main-content-infor-2"><CountUp duration={2} end={this.state.genBurn}  separator=","/> GEN</div>
                        <div className="main-content-infor-3">{strings["Total GEN Burned"]}</div>
                    </div>
                    <div className="main-content-infor-1">
                        <div className={`main-content-infor-2 ${this.state.isComingDays ? 'red' : ''}`}><CountUp duration={2} end={this.state.incomingDays}  separator=","/> Days</div>
                        <div className="main-content-infor-3">{strings["Countdown to Next Burn Event"]}</div>
                    </div>

                </div> */}
                <div className="main-content">
                    <iframe className="main-content-iframe" src="https://www.youtube.com/embed/s_H4Onwu2-A" title="genpool.io"
                        frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
                <div className="section-3">
                    <div className="div-block-9">
                        <h2 className="heading-5 sub">{strings["DailyReward"]}</h2>
                    </div>
                    <div className="div-block-8">
                        <div className="div-block-7"><img src={mJafI} alt="" /></div>
                        <div className="div-block-7"><img src={msbg3} alt="" /></div>
                        <div className="div-block-7"><img src={mYxkZ} alt="" /></div>
                        <div className="div-block-7"><img src={mi5Pz} alt="" /></div>
                        <div className="div-block-7"><img src={Logo_06nJ} alt="" /></div>
                        <div className="div-block-7"><img src={mkDyx} alt="" /></div>
                        <div className="div-block-7 h1"><img src={Logo_2Mk3} alt="" /></div>
                        <div className="div-block-7"><img src={Cmvk} alt="" /></div>
                        <div className="div-block-7"><img src={Logo_2Gv6} alt="" /></div>
                        <div className="div-block-7 h1"><img src={lvAW} alt="" /></div>
                        <div className="div-block-7 h1"><img src={m76HF} alt="" /></div>
                        <div className="div-block-7 h"><img src={mitVk} alt="" /></div>
                        <div className="div-block-7 h"><img src={mteG8} alt="" /></div>
                        <div className="div-block-7 h"><img src={mc4Hb} alt="" /></div>
                        <div className="div-block-7 h"><img src={m3doB} alt="" /></div>
                    </div>
                    <div className="div-block-10">
                        <div className="text-block-13 centre">
                            {strings["Introduce"]}
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <div id="Hero" className="hero">
                        <div>
                            <div className="hero-heading">GENPOOL</div>
                            <div className="hero-subheading">THE&nbsp;FIRST&nbsp;EOS&nbsp;VOTER&nbsp;EXCHANGE</div>
                        </div>
                    </div>
                </div>
                <div ref={(ref) => this.myRef = ref} id="RewardProxy">
                    <div className="div-block-36">
                        <div className="div-block-37">
                            <h1 className="heading-5-pickreward">{strings["PickRewards"]}</h1>
                        </div>
                        <div className="div-block-53">
                            <a href="https://genpool.zendesk.com/hc/en-us/articles/360045817031-GenPool-io-Getting-Started-Guide"
                                target="_blank" rel="noopener noreferrer" className="button-3-copy w-button-edit">{strings["HowToGuide"]}</a>
                        </div>
                    </div>

                    <div className="div-block-50 justify-center">
                        <NavLink to={GENPOOL_PROXY_PATH} className="div-block-51">
                            <div className="div-block-52">{strings["tab_genpool_proxy"]}</div>
                        </NavLink>
                        <NavLink to={PUBLIC_PATH} className="div-block-51">
                            <div className="div-block-52">{strings["tab_public"]}</div>
                        </NavLink>
                        <NavLink to={EXCHANGE_PATH} className="div-block-51">
                            <div className="div-block-52">{strings["tab_exchange"]}</div>
                        </NavLink>
                    </div>

                    <Switch>
                        <Route path={GENPOOL_PROXY_PATH}>
                            <div className="flex justify-center">
                                <GenpoolProxy strings={strings} referralUser={this.state.referralUser} />
                            </div>
                        </Route>
                        <Route exact path={PUBLIC_PATH}>
                            <div className="home-table">
                                <div className="container-table100">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="row100-head">
                                                    <th className="column2">
                                                        <div className="col-text">{strings["Rank"]}</div>
                                                    </th>
                                                    <th className="column3">
                                                        <div className="col-text">{strings["Name"]}</div>
                                                    </th>
                                                    <th className="column4">
                                                        <div className="col-text">{strings["StakedEOS"]}</div>
                                                    </th>
                                                    <th className="column5">
                                                        <span className="tooltiptext-main-column5">{strings["tooltip_fre"]}</span>
                                                        <div className="col-text">{strings["Frequency"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column9">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {proxyTable}

                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        loadingProxyTable?.queryProxiesTable ?
                                            <div className="rowrewarddiv-loading">
                                                <img src={spin} width={100} alt="" className="image-12" />
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </Route>
                        <Route exact path={EXCHANGE_PATH}>
                            <div className="home-table exchange">
                                <div className="container-table100 exchange">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="row100-head">
                                                    <th className="column2">
                                                        <div className="col-text">{strings["Rank"]}</div>
                                                    </th>
                                                    <th className="column3 exchange">
                                                        <div className="col-text">{strings["Name"]}</div>
                                                    </th>
                                                    <th className="column4 exchange">
                                                        <div className="col-text">{strings["StakedEOS"]}</div>
                                                    </th>
                                                    <th className="column5 exchange">
                                                        <span className="tooltiptext-main-column5">{strings["tooltip_fre"]}</span>
                                                        <div className="col-text">{strings["Frequency"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column6 exchange">
                                                        <span className="tooltiptext-main-column6">{strings["tooltip_reward"]}</span>
                                                        <div className="col-text">{strings["Rewards"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column9 exchange">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ExchangeProxyTable}

                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        loadingProxyTable?.queryProxiesTable ?
                                            <div className="rowrewarddiv-loading">
                                                <img src={spin} width={100} alt="" className="image-12" />
                                            </div> : null
                                    }
                                </div>
                            </div> 
                        </Route>
                    </Switch>
                    <div className="div-block-15" />
                </div>
                {/* define gradient color for rechart */}
                <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={0} stopColor="rgba(44, 186, 224,0.6)" stopOpacity={1} />
                        <stop offset={1} stopColor="rgba(44, 186, 224,0.01)" stopOpacity={1} />
                    </linearGradient>
                </svg>
                <Footer />

            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        loadingProxyTable: state.loading?.effects.proxyTable,
        wallet: state.wallet,
        proxyTableState: state.proxyTable,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...dispatch.wallet,
        dispatchProxyTable: dispatch.proxyTable,
        handleStakeFn: dispatch.staking.handleStakeFn,
        queryUnstaking: dispatch.staking.queryUnstaking,
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(MainPage))
