// React core
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer } from 'components'
import { Link } from "react-router-dom"

import { multilanguage, loadLanguages } from 'services/multilanguage'

import iziToast from 'izitoast';
import { TOAST, ACCOUNT_CONTRACT, BP_TABLE } from 'components/global'

import { fetchTable, pushAction, GetProxiesTable } from 'services/ApiService'
import history from 'services/History'

// import asus_talk from 'assets/images/06nJ-2.png'
import spin from 'assets/images/spiner.svg'
import sending from 'assets/images/sending_dark.svg'

const TAB_PROXY = 1
const TAB_BP = 2

const DAS_PROXY_PATH = '/dashboard/proxy'
const DAS_BP_PATH = '/dashboard/bp'

class Dasboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLoadingProxyTable: false,  //loading icon for proxy table
            IsLoadingBpsTable: false,  //loading icon for bps table

            tabId: TAB_PROXY,            //select tab
            RewardProxyTable: [],   //proxy table data
            BpDashBoardTable: [],   //pb table

            BpRegisterName: '',     //bp register name

            isSendingActionList: {
                regBp: false,
            }
        }

    }

    componentDidMount = async () => {
        if(this.props.location && this.props.location.pathname === DAS_PROXY_PATH){
            this.setState({
                tabId: TAB_PROXY,
            })

        }else if(this.props.location && this.props.location.pathname === DAS_BP_PATH){
            this.setState({
                tabId: TAB_BP,
            })
        }

        this.loadLanguages();
        this.initializeProxyTable();
        this.initializeBpTable();
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    initializeProxyTable = async () => {
        this.setState({
            IsLoadingProxyTable: true,
        })

        let tmpTable = []

        const ProxyTable = await GetProxiesTable()
        if(ProxyTable && ProxyTable.data){
            // console.log('tam proxyTable', ProxyTable.data())
            for(let i = 0; i < ProxyTable.data.publicProxies.length; ++i){
                tmpTable.push(ProxyTable.data.publicProxies[i])
            }
            for(let i = 0; i < ProxyTable.data.exchangeProxies.length; ++i){
                tmpTable.push(ProxyTable.data.exchangeProxies[i])
            }
        }

        tmpTable = tmpTable.sort((a, b) => (parseFloat(a.stakeEOS) < parseFloat(b.stakeEOS) ? 1 : -1))

        // console.log('tam ', tmpTable)

        this.setState({
            RewardProxyTable: tmpTable,
            IsLoadingProxyTable: false,
        })
    }

    initializeBpTable = async () => {
        this.setState({
            IsLoadingBpsTable: true,
        })

        const BpTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, "", "", 100);
        const BpDashBoardTable = BpTable.sort((a, b) => (parseFloat(a.fund) < parseFloat(b.fund) ? 1 : -1))

        // console.log('tam bttable', BpTable)
        if (BpTable && BpTable.length) {
            this.setState({
                BpDashBoardTable,
            })
        }

        this.setState({
            IsLoadingBpsTable: false,
        })
    }

    updateStatusActionList = (action, isSending) => {
        let actionList = this.state.isSendingActionList
        
        actionList[`${action}`] = isSending
        this.setState({
            isSendingActionList: actionList
        })
    }

    selectTab = (tabId) => () => {
        this.setState({
            tabId: tabId,
        })
    }

    updateBpRegisterName = (BpRegisterName) => {
        this.setState({
            BpRegisterName: BpRegisterName.target.value ? BpRegisterName.target.value : '',
        })
    }

    handleRegisterBp = async () => {
        //This will register your BP by sending 0.0001 to our smart contract
        // this.setState({
        //   IsLoading: true,
        // })
        if (this.state.BpRegisterName === '') {
            iziToast.show({
                message: this.props.strings["InvalidBp"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (this.props.wallet?.accountName) {
            this.updateStatusActionList('regBp', true)
            const actor = this.props.wallet.accountName
            const permission = this.props.wallet.permission

            const account = 'eosio.token'
            const action = 'transfer'

            const data = {
                from: actor,
                to: ACCOUNT_CONTRACT,
                quantity: '0.0001 EOS',
                memo: this.state.BpRegisterName,
            }

            const voteproducer = await pushAction(account, action, actor, data, permission)

            // console.log('tam reg', voteproducer)
            if (voteproducer) {
                iziToast.show({
                    message: this.props.strings["RegSuccess"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusActionList('regBp', false)
        } else {
            this.props.login()
        }
    }

    ChangeToUrl = (url) => () => {
        history.push(url)
    }

    render() {
        const { strings } = this.props

        var proxyTable = this.state.RewardProxyTable ? this.state.RewardProxyTable.map((data, index) => {
            return (
                <tr key={index} className="row100-body dashboard" onClick={this.ChangeToUrl(`/dashboard/proxy/${data.name}`)}>
                    <td className="column2-dashProxy">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3-dashProxy">
                        <img src={data.logo_256} width={32} height={32} alt="" className="proxyimage2" />
                        <div className="body1-copy-copy a-copy-copy">{data.name}</div>
                    </td>
                    <td className="column6-dashProxy">
                        <div className="column-text">{data.frequency}</div>
                    </td>
                    <td className="column4-dashProxy">
                        <div className="column-text">{data.stakeEOS.toLocaleString('en')}</div>
                    </td>
                    <td className="column7-dashProxy">
                        <div className="column-text">{data.rewards}</div>
                    </td>
                    <td className="column7-dashProxy">
                        <div className="column-text">{data.proxyFee}</div>
                    </td>
                </tr>
            )
        }) : null

        var bpsTable = this.state.BpDashBoardTable ? this.state.BpDashBoardTable.map((data, index) => {
            const imageUrl = `https://raw.githubusercontent.com/sun1211/eos-bp-logo/master/${data.account}/${data.account}.png`
            // const default_url = 'https://raw.githubusercontent.com/eoscafe/eos-bp-logo/master/aus1genereos/aus1genereos.png'
            const fund = parseFloat(data.fund)
            return (
                <tr key={index} className="row100-body dashboard" onClick={this.ChangeToUrl(`/dashboard/bp/${data.account}`)}>
                    <td className="column2-dashBp">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3-dashBp">
                        <img src={imageUrl} width={32} height={32} alt="" className="proxyimage2" />
                        <div className="body1-copy-copy a-copy-copy">{data.account}</div>
                    </td>
                    <td className="column4-dashBp">
                        <div className="column-text">{data.fund}</div>
                    </td>
                    <td className="column5-dashBp">
                        <div className="column-text">{data.proxy_donate ? data.proxy_donate.length : 0}</div>
                    </td>
                    <td className="column6-dashBp">
                        <div className="column-text">{`${fund > 0 ? `${fund < 10 ? 'Low funds' : 'Active'}` : 'Deactive'}`}</div>
                    </td>
                    <td className="column7-dashBp">
                        <div className="column-text">{data.weblink}</div>
                    </td>
                </tr>
            )
        }) : null

        return (
            <div className="body">
                <div className="section-7">
                    <div className="full-containter">
                        <div data-duration-in={300} data-duration-out={100} className="tabs-4 w-tabs">
                            <div className="tabs-menu-3 w-tab-menu">
                                <Link to={DAS_PROXY_PATH} data-w-tab="Tab 1" onClick={this.selectTab(TAB_PROXY)} className={`tab-link-tab-2 left w-inline-block w-tab-link ${this.state.tabId === TAB_PROXY ? 'w--current' : ''}`}>
                                    <div className="text-block-33">{strings["ProxyDashboard"]}</div>
                                </Link>
                                <Link to={DAS_BP_PATH} data-w-tab="Tab 2" onClick={this.selectTab(TAB_BP)} className={`tab-link-tab-2 left w-inline-block w-tab-link ${this.state.tabId === TAB_BP ? 'w--current' : ''}`}>
                                    <div className="text-block-32">{strings["BPDashboard"]}</div>
                                </Link>
                            </div>
                            <div className="w-tab-content">
                                <div data-w-tab="Tab 1" className={`w-tab-pane ${this.state.tabId === TAB_PROXY ? 'w--tab-active' : ''}`}>
                                    <div className="dashboard-table">
                                        <div className="container-table100">
                                            <div className="table100">
                                                <table>
                                                    <thead>
                                                        <tr className="row100-head">
                                                            <th className="column2-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["DasRank"]}</div>
                                                            </th>
                                                            <th className="column3-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["DasName"]}</div>
                                                            </th>
                                                            <th className="column6-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["DasRewards"]}</div>
                                                            </th>
                                                            <th className="column4-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["DasStakedEOS"]}</div>
                                                            </th>
                                                            <th className="column7-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["Rewards2"]}</div>
                                                            </th>
                                                            <th className="column7-dashProxy">
                                                                <div className="col-dashProxy-text">{strings["DasProxyFee"]}</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {proxyTable}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {
                                            this.state.IsLoadingProxyTable ?
                                                <div className="rowrewarddiv-loading">
                                                    <img src={spin} width={100} alt="" className="image-12" />
                                                </div> : null
                                        }
                                    </div>
                                </div>


                                <div data-w-tab="Tab 2" className={`w-tab-pane ${this.state.tabId === TAB_BP ? 'w--tab-active' : ''}`}>
                                    <div className="dashboard-table">
                                        <div className="container-table100">
                                            <div className="table100">
                                                <table>
                                                    <thead>
                                                        <tr className="row100-head">
                                                            <th className="column2-dashBp">
                                                                <div className="col-dashBp-text">#</div>
                                                            </th>
                                                            <th className="column3-dashBp">
                                                                <div className="col-dashBp-text">{strings["Account"]}</div>
                                                            </th>
                                                            <th className="column4-dashBp">
                                                                <div className="col-dashBp-text">{strings["DasFunds"]}</div>
                                                            </th>
                                                            <th className="column5-dashBp">
                                                                <div className="col-dashBp-text">{strings["Proxies"]}</div>
                                                            </th>
                                                            <th className="column6-dashBp">
                                                                <div className="col-dashBp-text">{strings["Status"]}</div>
                                                            </th>
                                                            <th className="column6-dashBp">
                                                                <div className="col-dashBp-text">{strings["Weblink"]}</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bpsTable}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        {
                                            this.state.IsLoadingBpsTable ?
                                                <div className="rowrewarddiv-loading">
                                                    <img src={spin} width={100} alt="" className="image-12" />
                                                </div> : null
                                        }
                                    </div>

                                    <div className="div-block-43">
                                        <div className="div-block-31 w-inline-block" />
                                        <div className="div-block-32" id="BpReg">
                                            <div className="w-form">
                                                <form name="email-form-2" data-name="Email Form 2">
                                                    <input type="text" className="text-field _0 w-input" maxLength={256} placeholder={this.props.strings["BpAccountName"]} 
                                                        value={this.state.BpRegisterName || ''} onChange={this.updateBpRegisterName} />
                                                    <div className="text-block-36">{strings["DasInfo"]}</div>
                                                </form>
                                                <div className="w-form-done">
                                                    <div>{strings["DasFormDone"]}</div>
                                                </div>
                                                <div className="w-form-fail">
                                                    <div>{strings["DasOops"]}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-block-34">
                                            <div className="bpregisterbutton" onClick={this.handleRegisterBp}>
                                            {
                                                this.state.isSendingActionList.regBp ? 
                                                    <img src={sending} alt="" className="image-dash-1"/>
                                                    : <div>{strings["DasRegister"]}</div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...dispatch.wallet,
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(Dasboard));
