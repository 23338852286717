import { ACCOUNT_CONTRACT, TOAST } from "components/global"
import iziToast from "izitoast"
import { fetchTable, pushTransaction } from "services/ApiService"
import { fmtSymbol, parseAmtSymbol, convertRexToEos, convertEosToRex } from "services/utils"

const initState = {
  version: 0,
  owner: "",
  vote_stake: "0 EOS",
  rex_balance: "",
  matured_rex: 0,
  rex_maturities: [],
  unstakingBalances: [],
  unstakeEos: 0,
  rexBalance: 0,
  rexPool: {
    total_lendable: "",
    total_rex: "",
    totalLendable: 0,
    totalRex: 0,
  },
  apy: 0,

  rexfund: 0,

  unstaking: [],
  totalUnstaking: 0,
  totalUnstakingInEos: 0,
  claimableAmt: 0,
  claimableAmtInEos: 0,
}

/**
 * @type {import("@rematch/core").Model}
 */
export const staking = {
  state: initState,
  reducers: {
    reset() {
      return initState
    },
    setStakingRex(state, payload) {
      return {
        ...state,
        ...payload,
      }
    },
    setRexfund(state, rexfund) {
      return {
        ...state,
        rexfund,
      }
    },

    setRexPool(state, rexPool) {
      // Instead of trying to fetch & calculate the annual reward, we know that it will be 31,250,000 EOS
      // for the new tokenomics, so we can just divide that by the total staked, the only inconsistency will
      // be that on testnets there will be an incorrect APY, but that's fine.
      const annualReward = 31250000
      const totalLendable = parseAmtSymbol(rexPool.total_lendable)[0]
      const apy = +parseFloat(((annualReward / totalLendable) * 100).toString()).toFixed(2)

      return {
        ...state,
        rexPool: {
          ...rexPool,
          totalLendable,
          totalRex: parseAmtSymbol(rexPool.total_rex)[0],
        },
        apy,
      }
    },
    computeUnstakeEos(state) {
      const unstakingBalances = state.unstakingBalances
      const savings = unstakingBalances.find((it) => it.savings)
      if (!savings) return state
      if (!state.rexPool) return state
      const rexPool = state.rexPool
      const rexBalance = savings.rex

      const now = +new Date()

      const unstakeEos = convertRexToEos(rexBalance, rexPool.totalLendable, rexPool.totalRex)

      const unstakingRaw = unstakingBalances
        .filter((x) => !x.savings)
        .map((x) => {
          return [x.rex, x.date]
        })

      const unstaking = unstakingRaw
        .filter((it) => +it[1] >= now)
        .map(([rex, date]) => {
          return [convertRexToEos(rex, rexPool.totalLendable, rexPool.totalRex), date, rex]
        })
        .sort((a, b) => +a[1] - b[1])

      const claimableAmt = unstakingRaw
        .filter(([_rex, date]) => +date < now)
        .map(([rex]) => rex)
        .reduce((a, c) => a + c, 0)

      const claimableAmtInEos = convertRexToEos(claimableAmt, rexPool.totalLendable, rexPool.totalRex)

      const totalUnstaking = unstaking.reduce((a, c) => a + c[2], 0)
      const totalUnstakingInEos = convertRexToEos(totalUnstaking, rexPool.totalLendable, rexPool.totalRex)

      return {
        ...state,
        unstakeEos,
        rexBalance,
        unstaking,
        totalUnstaking,
        totalUnstakingInEos,
        claimableAmt,
        claimableAmtInEos,
      }
    },
  },
  effects: (dispatch) => ({
    async queryRexbal(accountName) {
      if (accountName) {
        const lower_bound = accountName
        const upper_bound = accountName
        const contract = "eosio"
        const scope = contract
        const rows = await fetchTable(contract, scope, "rexbal", lower_bound, upper_bound)
        if (rows && rows.length) {
          const row = rows.find((it) => it.owner == accountName)
          if (row) {
            const fiveYearsFromNow = new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 5
            this.setStakingRex({
              ...row,
              unstakingBalances: row.rex_maturities.map((maturity) => ({
                rex: parseFloat((parseInt(maturity.second) / 10000).toString()),
                date: new Date(maturity.first),
                savings: +new Date(maturity.first) > +fiveYearsFromNow,
              })),
            })
          } else {
            console.trace(`👀 No rex blance found!`)
          }
        }
      }
    },

    /**
     *
     * @param {object} params
     * @param {string} params.unstakeAmt
     * @param {object} params.auth
     * @param {string} params.auth.actor
     * @param {string} params.auth.permission
     */
    async unStake(params, { staking: { rexPool, rexBalance } }) {
      const { auth, unstakeAmt } = params

      let rexAmt = convertEosToRex(unstakeAmt, rexPool.totalLendable, rexPool.totalRex)

      if (rexBalance < rexAmt) {
        // check if within a reasonable margin of error
        // if so, then just normalize to their max balance
        if (Math.abs(rexBalance - rexAmt) <= 10) {
          rexAmt = rexBalance
        } else {
          iziToast.show({
            message: "You do not have enough to unstake that amount.",
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.ErrorColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
          })
          return null
        }
      }

      const transaction = {
        actions: [
          {
            account: "eosio",
            name: "mvfrsavings",
            authorization: [auth],
            data: {
              owner: auth.actor,
              rex: fmtSymbol(rexAmt, "REX"),
            },
          },
        ],
      }

      const ok = await pushTransaction(transaction)
      if (ok) {
        iziToast.show({
          message: "Unstake successfully",
          progressBar: false,
          timeout: TOAST.TimeOut,
          backgroundColor: TOAST.SucessColor,
          messageSize: TOAST.MessageSize,
          position: TOAST.Position,
          messageColor: TOAST.MessageColor,
        })
      }
    },

    async claim(_, { wallet: { accountName, permission }, staking: { matured_rex, rexPool, claimableAmt, rexfund } }) {
      const authorization = [
        {
          actor: accountName,
          permission,
        },
      ]

      const maturedRex = matured_rex / 10000
      const claimable = maturedRex + claimableAmt
      const withdrawable = parseFloat(rexfund)
      const totalEos = convertRexToEos(claimable, rexPool.totalLendable, rexPool.totalRex) + withdrawable

      const actions = [
        {
          account: "eosio",
          name: "withdraw",
          authorization,
          data: {
            owner: accountName,
            amount: `${totalEos.toFixed(4)} EOS`,
          },
        },
      ]

      if (claimable > 0) {
        actions.unshift({
          account: "eosio",
          name: "sellrex",
          authorization,
          data: {
            from: accountName,
            rex: `${claimable.toFixed(4)} REX`,
          },
        })
      }

      const ok = await pushTransaction({ actions })
      if (ok) {
        iziToast.show({
          message: "Claim successfully",
          progressBar: false,
          timeout: TOAST.TimeOut,
          backgroundColor: TOAST.SucessColor,
          messageSize: TOAST.MessageSize,
          position: TOAST.Position,
          messageColor: TOAST.MessageColor,
        })
      }
    },

    async handleStakeFn(formData, rootState) {
      const strings = rootState.multilanguage.languages[rootState.multilanguage.currentLanguageCode]
      const { accountName, permission } = rootState.wallet
      const proxyName = formData.proxyName || process.env.REACT_APP_GENEREOS_PROXY
      const { stakeAmt, stakeRex, referralUser } = formData
      const actorAuth = {
        actor: accountName,
        permission,
      }

      if (accountName) {
        const actor = accountName

        // Make stake actions
        const transaction = {
          actions: [
            {
              account: "eosio",
              name: "voteproducer",
              authorization: [actorAuth],
              data: {
                voter: actor,
                proxy: proxyName,
                producers: [],
              },
            },
            {
              account: ACCOUNT_CONTRACT,
              name: "regvoter",
              authorization: [actorAuth],
              data: {
                voter: actor,
                referrer: referralUser ? referralUser : ACCOUNT_CONTRACT,
              },
            },
          ],
        }

        if (stakeRex && stakeAmt) {
          const amount = fmtSymbol(stakeAmt)
          // Add stake REX actions
          transaction.actions.push(
            {
              account: "eosio",
              name: "deposit",
              authorization: [actorAuth],
              data: {
                owner: actor,
                amount,
              },
            },
            {
              account: "eosio",
              name: "buyrex",
              authorization: [actorAuth],
              data: {
                from: actor,
                amount,
              },
            }
          )
        }

        const ok = await pushTransaction(transaction)

        if (ok) {
          iziToast.show({
            message: strings["VoteSuccessful"],
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.SucessColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
          })
        }
      } else {
        dispatch.wallet.login()
      }
    },

    async queryRexPool() {
      const contract = "eosio"
      const scope = contract
      const table = "rexpool"
      const rows = await fetchTable(contract, scope, table)
      if (rows[0]) {
        this.setRexPool(rows[0])
      }
    },

    async queryRexfund(accountName) {
      const contract = "eosio"
      const scope = contract
      const lower_bound = accountName
      const upper_bound = accountName
      const table = "rexfund"

      const rows = await fetchTable(contract, scope, table, lower_bound, upper_bound)
      if (rows && rows.length) {
        const row = rows[0]
        this.setRexfund(parseFloat(row.balance.split(" ")[0]))
      }
    },

    async queryUnstaking(_, { wallet: { accountName } }) {
      await Promise.all([this.queryRexPool(), this.queryRexbal(accountName), this.queryRexfund(accountName)])
      this.computeUnstakeEos()
    },
  }),
}
