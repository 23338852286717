import { add } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom"
import { Area, AreaChart, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { commaNumber, fmtBalance, parseAmtSymbol, randomId, readableNumber } from "services/utils"
import styled from "styled-components"
import { ReactComponent as EOSsymbol } from "../../assets/images/EOS-symbol.svg"

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  line-height: 1.25;
  width: calc(600px + 3rem);
  background: #0f172a;
  border-radius: 4px;
  padding: 3rem;
  margin: 1.5rem 0 1.5rem 0;

  @media only screen and (max-width: 768px) {
    padding: 0.5rem;
    width: calc(600px + 0.5rem);
  }

  & .text-sm {
    font-size: 0.875rem;
  }

  & .text-2xl {
    font-size: 1.75rem;
  }

  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  & .opacity-70 {
    opacity: 0.7;
  }

  & .opacity-50 {
    opacity: 0.5;
  }

  & .w-full {
    width: 100%;
  }

  & .flex {
    display: flex;
  }

  & .justify-between {
    justify-content: space-between;
  }

  & .border-b {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }

  & .mb-1 {
    margin-bottom: 0.25rem;
  }

  & .mt-2 {
    margin-top: 0.5rem;
  }

  & .mt-5 {
    margin-top: 1.25rem;
  }

  & .pb-1 {
    padding-bottom: 0.25rem;
  }

  & .text-primary {
    color: #fde047;
  }

  & .font-bold {
    font-weight: 700;
  }

  & .font-style-italic {
    font-style: italic;
  }

  & .font-extrabold {
    font-weight: 900;
  }

  & .text-underline {
    text-decoration: underline;
  }

  & .text-opacity-50 {
    color: rgb(255 255 255 / 50%);
  }
`

const TabsContainer = styled.div`
  display: flex;
  overflow: hidden;
  margin-bottom: 0.5rem;
`

const TabItem = styled(NavLink)`
  height: 40px;
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  border-right: solid 1px #fff;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
  text-decoration: unset;
  font-weight: 600;

  &.active {
    background-color: white;
    color: black;
  }

  &:last-child {
    flex: unset;
    width: 48px;
    border-radius: 0 4px 4px 0;
  }

  &:first-child {
    border-left: solid 1px #fff;
    border-radius: 4px 0 0 4px;
  }

  &:hover {
    background-color: white;
    color: black;
  }
`

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  background: linear-gradient(142deg, #ffffff1f 8.16%, #ffffff05 78.58%);
  box-shadow: 0 4px 4px #00000040, inset 0 0 0 2px #ffffff38;
  border-radius: 4px;
  color: white;
`

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  background-color: #000000bf;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.31);
  margin-bottom: 12px;

  &:has(input:focus) {
    border-color: #fde047;
  }

  & > input[type="number"] {
    color: white;
    margin: unset;
    outline: none;
    background: transparent;
    border: unset;
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;
  }

  & > button {
    height: 40px;
    background: transparent;
    border-radius: 4px;
    border: solid 1px white;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
  }
`

const IconContainer = styled.div`
  padding: 0 1rem 0 1rem;
  display: flex;
  align-items: center;
`

const Help = styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: end;
  margin-bottom: 0.5rem;
`

const MessageBox = styled.div`
  padding: 12px;

  border-radius: 4px;
  border: solid 1px #ffffff80;
  font-size: 0.75rem;
  line-height: 1rem;

  & > p {
    margin: unset;
  }
`

const Button = styled.button`
  cursor: pointer;
  height: 64px;
  width: 100%;
  border-radius: 0.25rem;
  background-color: white;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #051529;
  border: unset;

  &:disabled {
    color: white;
    border: solid 2px white;
    background-color: transparent;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: white;
    border: solid 2px white;
    background-color: transparent;
  }
`

const StakeContainer = styled.div`
  margin: 3rem;
  width: 100%;
  min-height: 560px;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media only screen and (max-width: 768px) {
    margin: 1.2rem;
  }
`

const EstEarnChart = styled(({ className, stakeAmt, calEarnData }) => {
  const [estDay, setEstDay] = useState(90)
  return (
    <div className={className}>
      <span>
        Estimated yield over the next{" "}
        <b>{estDay <= 365 ? <u>{estDay} days</u> : <u>{parseFloat(estDay / 365).toFixed(1)} years</u>}</b> at this APY
      </span>
      <ResponsiveContainer height={180} width="100%">
        <LineChart
          margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
          data={calEarnData(estDay).map((it) => ({
            ...it,
            balance: +parseFloat(it.balance - stakeAmt).toFixed(2),
          }))}
        >
          <XAxis dataKey="day" allowDataOverflow />
          <YAxis width={50} tickFormatter={readableNumber} />
          <Tooltip />
          <Line type="monotone" dataKey="balance" stroke="#8884d8" dot={false} />
        </LineChart>
      </ResponsiveContainer>

      <input
        className="est-day-slider"
        type="range"
        value={estDay}
        onChange={(evt) => setEstDay(evt.target.value)}
        min="90"
        max={365 * 10}
        step="1"
      />
      <div className="est-day-slider__legend opacity-70">
        <span className="est-day-slider__legend__start">90d</span>
        <span className="est-day-slider__legend__end">10yr</span>
      </div>
    </div>
  )
})`
  .est-day-slider {
    width: 100%;
    height: 0.125rem;
    margin-bottom: 0.5rem;
    background: rgb(255 255 255 / 20%);
    cursor: pointer;
  }

  .est-day-slider__legend {
    display: flex;
    justify-content: space-between;
  }
`

const InputCP = ({ onChange, value, clickBtnMax, maxValue }) => {
  return (
    <InputContainer>
      <IconContainer>
        <EOSsymbol />
      </IconContainer>
      <input
        placeholder="EOS Amount"
        type="number"
        step={0.0001}
        onChange={(evt) => {
          if (typeof onChange == "function") {
            onChange(Number(`${evt.target.value}`))
          }
        }}
        value={value}
        min={0}
        max={maxValue}
      />
      <button type="button" onClick={clickBtnMax}>
        MAX
      </button>
    </InputContainer>
  )
}

const CheckBoxContainer = styled.div`
  display: flex;
  & > * {
    cursor: pointer;
  }

  & > input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    appearance: auto;
  }
`

const CheckBox = ({ id, onChange, value }) => {
  return (
    <CheckBoxContainer>
      <input type="checkbox" defaultChecked={value} onChange={onChange} id={id} name={id} value={id} />
      <label htmlFor={id}>
        Stake to <b>REX</b> as well as Genpoolproxy?
      </label>
    </CheckBoxContainer>
  )
}

const idCheckStakeRex = randomId()

export const GenpoolProxyStake = ({ accountName, eosBalance, referralUser }) => {
  const { APY, dailyRate } = useSelector(({ staking: { apy }, proxyTable: { genpoolProxy } }) => {
    if (!genpoolProxy) return apy
    const apr = parseFloat(genpoolProxy.APR?.split(" ")[0]) || 0
    const APY = +parseFloat(`${apr + apy}`).toFixed(2)
    return { APY, dailyRate: APY / 100 / 365 }
  })
  const [stakeAmt, setStakeAmt] = useState("")
  const [stakeRex, _setStakeRex] = useState(true)
  const dispatch = useDispatch()

  const calEarnData = useCallback(
    (estD) => {
      const data = [
        {
          day: 0,
          balance: stakeAmt,
        },
      ]
      for (let i = 1; i <= estD; i++) {
        const curBalance = data[i - 1].balance
        const newBalance = +parseFloat(curBalance * (1 + dailyRate)).toFixed(2)
        data.push({
          day: i,
          balance: newBalance,
        })
      }
      return data.slice(1)
    },
    [stakeAmt, dailyRate]
  )

  return (
    <StakeContainer>
      <FlexColumn>
        <span
          style={{
            fontWeight: 900,
            lineHeight: "1.5rem",
          }}
        >
          ~APY 🔥
        </span>
        <span className="text-primary font-extrabold text-2xl">{APY}%</span>
      </FlexColumn>
      <form
        onSubmit={async (evt) => {
          evt.preventDefault()
          await dispatch.staking.handleStakeFn({
            stakeAmt,
            stakeRex,
            referralUser,
          })
          await Promise.all([dispatch.wallet.fetchBalance(accountName), dispatch.staking.queryUnstaking()])
        }}
      >
        <div className="w-full">
          <InputCP
            value={stakeAmt}
            onChange={setStakeAmt}
            clickBtnMax={() => {
              setStakeAmt(parseAmtSymbol(eosBalance)[0])
            }}
            maxValue={parseAmtSymbol(eosBalance)[0]}
          />
          {/* <CheckBox
            id={idCheckStakeRex}
            value={stakeRex}
            onChange={() => {
              setStakeRex(!stakeRex)
            }}
          /> */}

          <Help>
            <span>You have {eosBalance} available</span>
          </Help>
        </div>

        <Button type="submit" disabled={stakeRex && !stakeAmt}>
          Stake
        </Button>
      </form>

      <div className="w-full">
        <div className="flex justify-between border-b mb-1 pb-1 opacity-70 text-sm">
          <span>You will stake</span>
          <span>{stakeAmt} EOS</span>
        </div>

        <div className="flex justify-between border-b mb-1 pb-1 text-sm">
          <span>Estimated daily yield</span>
          <span>{commaNumber(stakeAmt * dailyRate)} EOS</span>
        </div>

        <div className="flex justify-between mb-1 pb-1 text-sm text-primary font-extrabold">
          <span>Estimated annual yield</span>
          <span>{commaNumber(stakeAmt * (APY / 100))} EOS</span>
        </div>
      </div>
      {!!stakeAmt && (
        <MessageBox>
          <EstEarnChart {...{ stakeAmt, calEarnData }} />
        </MessageBox>
      )}

      <MessageBox className="font-style-italic">
        <p className="opacity-50">The APY is an estimate, and fluctuates based on the total amount of staked EOS.</p>

        <p className="opacity-70">
          <b>Unstaking starts a 21 day release timer.</b>
        </p>
        <br />

        <p className="opacity-50">
          <b>
            <u>You will never get back less EOS.</u>
          </b>
        </p>
      </MessageBox>
    </StakeContainer>
  )
}

export const GenpoolProxyUnstake = ({ accountName, permission }) => {
  const [claimableDate, setClaimableDate] = useState(new Date())
  const [unstakeAmt, setUnstakeAmt] = useState("")
  const dispatch = useDispatch()
  const { unstakeEos } = useSelector((state) => state.staking)
  useEffect(() => {
    // compute next 21 days
    setClaimableDate(add(new Date(), { days: 21 }))
  }, [dispatch, accountName])
  return (
    <StakeContainer>
      <form
        onSubmit={async (evt) => {
          evt.preventDefault()
          await dispatch.staking.unStake({
            unstakeAmt,
            auth: {
              actor: accountName,
              permission,
            },
          })

          await Promise.all([dispatch.wallet.fetchBalance(accountName), dispatch.staking.queryUnstaking()])

          setUnstakeAmt("")
        }}
      >
        <div className="w-full">
          <InputCP
            value={unstakeAmt}
            onChange={setUnstakeAmt}
            clickBtnMax={() => {
              setUnstakeAmt(unstakeEos)
            }}
            maxValue={unstakeEos}
          />
          <Help>
            <span>You have {fmtBalance(unstakeEos)} EOS staked</span>
          </Help>
        </div>

        <Button type="submit" disabled={unstakeAmt <= 0 || unstakeAmt > unstakeEos}>
          Unstake
        </Button>
      </form>

      <div className="w-full">
        <div className="flex justify-between border-b mb-1 pb-1 opacity-70 text-sm">
          <span>Will be available to claim on</span>
          <span>{claimableDate.toDateString()}</span>
        </div>

        <div className="flex justify-between mb-1 pb-1 text-sm font-extrabold">
          <span>You are unstaking</span>
          <span>{`${unstakeAmt || 0} EOS`}</span>
        </div>
      </div>

      <MessageBox className="font-style-italic">
        <p className="opacity-50">
          Unstaking balances will still accrue rewards until they are claimed. However, any operation you do (staking
          more for instance) will automatically claim your fully unstaked positions.
        </p>
      </MessageBox>
    </StakeContainer>
  )
}

const InnerGlassBox = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 0 2px #fff3, 0 2px 4px #0006;
  background: #ffffff0d;
  backdrop-filter: blur(6.5999999046px);
  padding: 20px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  & > table {
    width: 100%;

    th {
      font-size: 0.75em;
    }

    td {
      font-size: 0.85em;
    }

    th,
    td {
      line-height: 1.25rem;
      margin-bottom: 0.25rem;
      padding-bottom: 0.25rem;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid rgb(65 73 91);
    }

    > thead > tr {
      border-bottom: 1px solid rgb(65 73 91);

      > th:first-child {
        text-align: left;
      }
      > th:last-child {
        text-align: right;
      }
    }
    > tbody > tr {
      > td:first-child {
        text-align: left;
      }

      > td:last-child {
        text-align: right;
      }
    }
  }
`

const DivAvailableClaim = styled.div`
  text-align: left;
`

const DivAvailableClaimEos = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgb(253 224 71);
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const GenpoolProxyClaim = () => {
  const { unstaking, claimableAmtInEos, totalUnstakingInEos } = useSelector((state) => state.staking)
  const dispatch = useDispatch()

  return (
    <StakeContainer>
      {claimableAmtInEos <= 0 ? (
        <Button disabled>Nothing to claim</Button>
      ) : (
        <div>
          <Button
            onClick={async () => {
              await dispatch.staking.claim()
              await dispatch.staking.queryUnstaking()
            }}
          >
            Claim rewards
          </Button>
          <DivAvailableClaimEos>
            <span>You can claim</span>
            <span>{claimableAmtInEos} EOS</span>
          </DivAvailableClaimEos>
        </div>
      )}

      {!!totalUnstakingInEos && (
        <DivAvailableClaim>
          <section className="current-staking">
            <section className="text-sm">Currently unstaking</section>
            <section className="text-3xl font-bold">{totalUnstakingInEos} EOS</section>
          </section>

          <section className="text-2 mt-5 text-opacity-50">Below are your unstaking balances.</section>

          <InnerGlassBox>
            <table>
              <thead>
                <tr className="text-opacity-50">
                  <th>Amount</th>
                  <th>Date available</th>
                </tr>
              </thead>
              <tbody>
                {unstaking.map((it) => (
                  <tr key={it[1].toDateString()}>
                    <td>{it[0]} EOS</td>
                    <td>{it[1].toDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InnerGlassBox>
        </DivAvailableClaim>
      )}
    </StakeContainer>
  )
}

const ApyContainer = styled.section`
  background-color: white;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;

  .area-char-main-title-text {
    color: black;
  }
`

export const GenpoolProxyApy = () => {
  const strings = useSelector((state) => state.multilanguage.languages[state.multilanguage.currentLanguageCode])
  const data = useSelector((state) => state.proxyTable.genpoolProxy)
  const historyApr = useSelector((state) => state.proxyTable.historyApr)

  if (!data.name) {
    return <></>
  } else {
    // console.log(`render data.name:`, data.name)
    if (!historyApr[data.name]) {
      return <></>
    }
  }

  return (
    <ApyContainer>
      <div className="area-char-main">
        <div className="area-char-main-title">
          <div className="area-char-main-title-text">{strings["Genpool Income Trend"]}</div>
          <div className="area-char-main-title-text">
            {strings["Highest APR Yday"]}:{" "}
            <b>
              {Math.max.apply(
                Math,
                historyApr[`${data.name}`].map((it) => it.apy)
              )}{" "}
              %
            </b>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={480}>
          <AreaChart data={historyApr[`${data.name}`]} margin={{ top: 5, right: 16, left: 5, bottom: 24 }}>
            <XAxis dataKey="time">
              <Label value={strings["Date"]} offset={0} position="insideBottom" />
            </XAxis>
            <YAxis label={{ value: strings["Yield"], angle: -90, position: "insideLeft" }} />

            <Tooltip />
            <Area type="monotone" dataKey="apy" stroke="#2cbae0" fillOpacity={1} fill="url(#colorUv)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </ApyContainer>
  )
}

export const GenpoolProxy = ({ strings, referralUser }) => {
  const { path } = useRouteMatch()
  const { accountName, eosBalance, permission } = useSelector((state) => state.wallet)
  const dispatch = useDispatch()

  useEffect(() => {
    if (accountName) {
      dispatch.wallet.fetchBalance(accountName)
      dispatch.staking.queryUnstaking()
    } else {
      dispatch.wallet.reset()
      dispatch.staking.reset()
      dispatch.staking.queryRexPool()
    }
  }, [accountName])

  return (
    <Container>
      <TabsContainer>
        <TabItem to={`${path}/stake`}>
          <div className="text-sm">{strings["tab_genpool_proxy_stake"]}</div>
        </TabItem>
        <TabItem to={`${path}/unstake`}>
          <div className="text-sm">{strings["tab_genpool_proxy_unstake"]}</div>
        </TabItem>
        <TabItem to={`${path}/claim`}>
          <div className="text-sm">{strings["tab_genpool_proxy_claim"]}</div>
        </TabItem>
        <TabItem to={`${path}/apr`}>
          <div className="text-sm">{strings["tab_genpool_proxy_apr"]}</div>
        </TabItem>
      </TabsContainer>

      <Body>
        <Switch>
          <Route path={`${path}/stake`}>
            <GenpoolProxyStake {...{ accountName, eosBalance, referralUser }} />
          </Route>

          <Route path={`${path}/unstake`}>
            <GenpoolProxyUnstake {...{ accountName, permission }} />
          </Route>

          <Route path={`${path}/claim`}>
            <GenpoolProxyClaim {...{ accountName, permission }} />
          </Route>
          <Route path={`${path}/apr`}>
            <GenpoolProxyApy />
          </Route>
        </Switch>
      </Body>
    </Container>
  )
}

export default GenpoolProxy
