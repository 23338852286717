export { proxyTable } from "./proxyTable"
export { staking } from "./staking"
export { wallet } from "./wallet"

/**
 * @type {import("@rematch/core").Model}
 */
export const app = {
  state: {},
  reducers: {},
  effects: (_dispatch) => ({}),
}

/**
 * @type {import("@rematch/core").Model}
 */
export const message = {
  state: {},
  reducers: {
    setErrorMsg(_state, payload) {
      return payload
    },
    clear() {
      return {}
    },
  },
}
