import { init } from "@rematch/core";
import loadingPlugin from "@rematch/loading"

import * as models from "./models";
import { createMultilanguageReducer } from "./multilanguage";

const store = init({
  models,
  plugins: [loadingPlugin()],
  redux: {
    reducers: {
      multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
});

export default store;
